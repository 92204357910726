.complain_section {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.complain_modal .ant-modal-content {
  background-color: #f7f8fa;
}
.container_title {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  margin: -10px auto 0;
}
.complain_header__content {
  width: 100%;
  margin: 0 auto;
}
.complain_label {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #6d7885 !important;
  margin-bottom: 12px;
}
.complaint_selections {
  margin: 34px 0 24px;
}
.complain_selector {
  background: #f9f9f9;
  border: 2px solid #e7e9e8;
  border-radius: 100px;
  padding: 16px 20px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.complain_selector_aktive {
  border: 2px solid #ee4719;
}
.complain_selector p {
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 0;
}
.complain_selector span {
  width: 27px;
  height: 27px;
  border: 2px solid #e7e9e8;
  border-radius: 50%;
}
.complain_selector svg {
  height: 27px;
}
.complain_form {
  margin-bottom: 40px;
}
.complain_textarea {
  background: #f9f9f9 !important;
  border: 2px solid #e7e9e8 !important;
  border-radius: 17px !important;
}
.suggestion_breadcrumb__span {
  transition: 0.3s;
}
.suggestion_breadcrumb__span:hover {
  color: #008c8c !important;
}
.ant-rate-star-zero .ant-rate-star-second {
  color: #e7e9e8 !important;
  font-size: 25px;
}
.ant-rate-star-full .ant-rate-star-second {
  color: #fadb14 !important;
  font-size: 25px;
}
.complain_section .editprofile_main_button {
  width: 100%;
  margin-top: 32px;
}
.complain_section button {
  height: 50px;
  width: 100% !important;
}
.full_width {
  width: 100%;
}
.suggestion_title {
  margin-bottom: 31px;
}
