.otp-input {
  display: flex;
  gap: 5px;
}
.otp-input input {
  margin: 0 5px;
  text-align: center;
  line-height: 40px !important;
  font-size: 24px !important;
  border: solid 1px #ccc !important;
  box-shadow: 0 0 5px #ccc inset;
  outline: none;
  width: 20%;
  transition: all 0.2s ease-in-out;
  border-radius: 3px;
}

.otp-input:focus {
  border-color: var(--main);
  box-shadow: 0 0 5px var(--main) inset;
}

.otp-input::selection {
  background: transparent;
}
