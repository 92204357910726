.worker_section {
  width: 100%;
}
.worker_section .works_name__div {
  width: 100% !important;
  /* margin-top: 7px; */
}
.worker_section .works_name__div:nth-child(2) {
  margin-top: 22px;
}
.worker_name {
  line-height: 25px !important;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  gap: 8px;
}
.worker_section .worker_img__div {
  margin-right: 20px;
  display: flex;
  justify-content: center;
} 
.worker_section .works_price_div {
  margin-top: 12px; 
} 
.worker_section .works_description__div {
  margin-top: 9px; 
} 
.worker_section .works_lover__div {
  display: flex;
  justify-content: space-between;
  margin-left: 0;
}
.worker_section .works_lover__div img {
  height: 27px;
}
.worker_img {
  margin: 0 auto;
  width: 52px;
  height: 52px;
  object-fit: cover;
  border-radius: 50%;
} 
.workerfull_section .worksfull_owner__div {
  margin-top: 0 !important;
}
.workerfull_section .works_price_div {
  width: 40%;
}
.workerfull_section .works_lover__div {
  width: 5%;
}
.checkbox_row {
  display: flex;
}
.checkbox_col {
  width: calc(100% / 7);
}
.works_lover__full {
  margin-left: auto;
}
.workerfull_icons{
  display: flex;
  align-items: center;
  gap: 17px;
}
.workerfull_icons svg{
  cursor: pointer;
}
.workerfull_section .worksful_owner__img{
  width: 90px !important;
  height: 90px !important;
}
