* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  width: 90vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center; 
  position: relative;
  left: 6vmin;
  text-align: center;
  margin: 0 auto;
}

.cog-wheel1,
.cog-wheel2 {
  transform: scale(0.7);
}
.cog-wheel2 {
  z-index: 10;
}
.cog1,
.cog2 {
  width: 40vmin;
  height: 40vmin;
  border-radius: 50%;
  border: 6vmin solid #f3c623;
  position: relative;
  animation-duration: 16s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.cog1 {
  animation-name: rotate;
}
.cog2 {
  animation-name: reverse;
}
@keyframes rotate {
  from {
    transform: rotate(-360deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes reverse {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
.cog2 {
  border: 6vmin solid #4f8a8b;
}

.top,
.down,
.left,
.right,
.left-top,
.left-down,
.right-top,
.right-down {
  width: 10vmin;
  height: 10vmin;
  background-color: #f3c623;
  position: absolute;
}

.cog2 .top,
.cog2 .down,
.cog2 .left,
.cog2 .right,
.cog2 .left-top,
.cog2 .left-down,
.cog2 .right-top,
.cog2 .right-down {
  background-color: #4f8a8b;
}

.top {
  top: -14vmin;
  left: 9vmin;
}

.down {
  bottom: -14vmin;
  left: 9vmin;
}

.left {
  left: -14vmin;
  top: 9vmin;
}

.right {
  right: -14vmin;
  top: 9vmin;
}

.left-top {
  transform: rotateZ(-45deg);
  left: -8vmin;
  top: -8vmin;
}

.left-down {
  transform: rotateZ(45deg);
  left: -8vmin;
  top: 25vmin;
}

.right-top {
  transform: rotateZ(45deg);
  right: -8vmin;
  top: -8vmin;
}

.right-down {
  transform: rotateZ(-45deg);
  right: -8vmin;
  top: 25vmin;
}

.cog2 {
  position: relative;
  left: -10.2vmin;
  bottom: 10vmin;
}

h1 {
  color: #142833; 
}

.first-four {
  position: relative;
  left: 6vmin;
  font-size: 40vmin;
}

.second-four {
  position: relative;
  right: 18vmin;
  font-size: 40vmin;
}

.wrong-para { 
  position: absolute;
  bottom: 15vmin;
  padding: 3vmin 12vmin 3vmin 3vmin;
  font-weight: 600;
  color: #092532;
}
