@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
body {
  overflow-x: hidden;
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: "Roboto", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
  --main: #008c8c;
  --hover: #ffdb41;
}

.App {
  background-color: #efefef;
  min-height: 100vh;
  width: 100%;
  display: inline-block;
}

.app_content {
  max-width: 1400px;
  min-height: calc(68vh - 117px);
  width: 90%;
  margin: 0 auto;
  padding-bottom: 50px;
}

.arrow_up__content {
  width: 40px;
  height: 40px;
  background-color: var(--main);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.arrow_up__content svg {
  color: white;
  font-size: 20px;
}

.ant-back-top {
  right: 30px !important;
}

label {
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 20px !important;
  letter-spacing: -0.24px !important;
  color: #6d7885 !important;
  height: auto !important;
}

input,
.ant-input,
.ant-input-number,
.ant-input-affix-wrapper,
.ant-picker {
  border: 1px solid #e7e9e8 !important;
  border-radius: 14px !important;

  font-weight: 400 !important;
  font-size: 17px !important;
  line-height: 20px !important;
  color: #000000 !important;
  height: 48px !important;
}
.ant-input-affix-wrapper {
  height: 50px !important;
}
.ant-input-number {
  width: 100% !important;
  border: none !important;
}
textarea.ant-input {
  font-weight: 400;
  font-size: 15px !important;
  line-height: 20px !important;
  letter-spacing: -0.24px;
  color: #000000 !important;
}

.ant-picker input {
  border: none !important;
  height: auto !important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 14px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #e7e9e8 !important;
  border-radius: 14px;
  height: 50px !important;
  display: flex;
  align-items: center;
}

.ant-form-item,
.ant-form-item-has-error,
.ant-form-item-has-success,
.ant-form-item-with-help,
input,
.ant-input,
.ant-input-number,
.ant-input-affix-wrapper,
.ant-picker {
  transition: 0 !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  margin-left: 1.5px !important;
}

.ant-checkbox-wrapper {
  font-size: 17px !important;
  line-height: 20px !important;
  color: #000000 !important;
}

.ant-radio-wrapper {
  font-weight: 400 !important;
  font-size: 17px !important;
  line-height: 20px !important;
  color: #000000 !important;
  display: flex !important;
  align-items: center !important;
  margin-bottom: 8px !important;
}

.ant-radio-wrapper:last-child {
  margin-bottom: 0px !important;
}

.ant-radio-checked .ant-radio-inner {
  border: 10px solid #008c8c !important;
}
.ant-radio {
  top: 0 !important;
}

.anticon-exclamation-circle {
  display: none !important;
}

.ant-drawer-wrapper-body {
  background: #f7f8fa !important;
}
.ant-collapse-arrow {
  display: none !important;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0px !important;
  padding-bottom: 0px;
  padding: 0px !important;
}

.masked_input_wrap {
  outline: none;
  border: none;
  border-bottom: 1px solid var(--main);
  padding: 6.5px 11px;
  font-size: 16px;
  margin: 10px 0 24px;
  background-color: #e8f0fe;
}

.login_section .masked_input {
  border: none !important;
  outline: none;
  background-color: #e8f0fe !important;
}

.nodata_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.nodata_title {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  margin-bottom: -30px;
  font-weight: 600;
  font-size: 32;
  z-index: 10 !important;
  font-style: italic;
}

.share_section {
  display: flex;
  align-items: center;
}

.share_icons {
  width: 120%;
  display: flex;
  grid-gap: 7px;
  gap: 7px;
  padding: 5px 0px 2px;
}

.share_icons img {
  height: 40px;
  cursor: pointer;
}
.link {
  cursor: pointer;
  transition: 0.3s;
}
.link:hover {
  color: var(--main);
}

/* Tab classes */
.ant-tabs-nav {
  margin-bottom: 25px;
}
.ant-tabs-nav-list {
  width: 100%;
}
.ant-tabs-nav-list .ant-tabs-tab {
  width: 50%;
  text-align: center;
}
.ant-tabs-nav-list .ant-tabs-tab-btn {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #606266;
}
.ant-tabs-nav-list:hover .ant-tabs-tab-btn {
  color: var(--main);
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--main);
}
.ant-tabs-ink-bar {
  background: var(--main);
}
.singup-tab > .ant-tabs-nav {
  display: none !important;
}
.login-main .ant-tabs-content {
  padding: 0px 2px 5px;
}
.ant-tabs-ink-bar {
  background: var(--main) !important;
}
.ant-input-affix-wrapper input {
  border: none !important;
}
.ant-input-affix-wrapper {
  padding: 0 11px !important;
}

@media only screen and (max-width: 700px) {
  .app_content {
    width: 94%;
  }
}

.filter_section {
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
}
.filter_search__content {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 10px;
  padding: 0 20px 0 10px;
}
.filter_search__icon {
  object-fit: cover;
  height: 25px;
  cursor: pointer;
  transition: 0.2s;
  margin-right: 20px;
}
.filter_location__icon {
  cursor: pointer;
}
.filter_search__icon:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.filter_search__input {
  width: 90%;
  border: none;
  outline: none;
  color: var(--main);
  font-weight: 400;
  font-size: 17px !important;
  line-height: 20px;
  color: #000000;
  border: none !important;
}
.filter_location__button {
  height: 60px;
  font-size: 22px; 
  font-weight: 400;
  color: #757575;
  background-color: white;
  border-radius: 15px;
  margin-left: 30px;
  border: none;
  padding: 1px 20px;
  display: flex;
  align-items: center;
}
.filter_location__button:first-child {
  border-radius: 14px;
  padding: 1px 15px;
}
.filter_location__button
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 45px !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #07e0e0 !important;
}
.ant-select-selector:hover {
  border: 1px solid #d9d9d9 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
  box-shadow: none !important;
}
.filterContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 !important;
}
.filterContent .btn-moving-gradient {
  padding: 5px 40px !important;
  float: right;
}
.filterContent .buttons-section {
  display: block;
  margin-left: auto;
}
.filter_select {
  width: 200px;
}
.filterContent_main {
  width: 100%;
}
.filterContent_form {
  width: 100%;
  margin: 0 auto !important;
}
.filtercontent_main h1 {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.38px;
  color: #000000;
}
.filter_confirm_button {
  width: 100%;
  margin-top: 24px;
}
.filter_confirm_button button {
  width: 100%;
  height: 57px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}
@media only screen and (max-width: 1350px) {
  .filter_search__content {
    width: 40%;
  }
}
@media only screen and (max-width: 1100px) {
  .filter_section {
    width: 100%;
  }
}
@media only screen and (max-width: 1000px) {
  .filter_section {
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
  }
  .filter_search__content {
    width: 100%;
  }
  .filter_location__button {
    margin-left: 0;
  }
}
@media only screen and (max-width: 650px) {
  .ant-modal {
    width: 90% !important;
  }
  .filterContent_form {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .filter_search__content {
    width: calc(100% - 94px);
    order: 1;
  }
  .filter_location__content {
    width: 100%;
    order: 3;
  }
  .filter_modal_div {
    order: 2;
  }
  .filter_location__button:first-child {
    width: 100%;
    border-radius: 14px;
    padding: 1px 10px;
  }
  .filter_section .ant-select {
    width: calc(50% - 5px);
  }
}
@media only screen and (max-width: 500px) {
  .ant-modal {
    width: 100% !important;
  }
}

@media only screen and (max-width: 450px) {
  .filter_search__content {
    width: calc(100% - 75px);
    height: 48px;
    padding: 0px 0px 0 5px;
  }
  .filter_search__icon {
    height: 25px;
    margin-right: 10px;
  }
  .filter_location__button {
    height: 48px !important;
    padding: 1px 8px !important;
  }
  .filter_location__button
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 37px !important;
  }
  .filter_search__content {
    order: 1;
  }
  .filter_location__content {
    width: 100%;
    order: 3;
  }
  .filter_modal_div {
    order: 2;
  }
  .filter_section .ant-select {
    width: calc(50% - 5px);
  }
}

.form_container {
  width: 856px;
  margin: 0px auto;
}
.container_title {
  margin-top: 32px;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  color: #212121;
}
.container_title2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #212121;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 2px solid #f0f0f0;
}
.half_width {
  width: calc(50% - 24px);
}
.full_width {
  width: 100%;
}
.keyword_width {
  width: calc(100% - 40px) !important;
}
.writeBulletin_section {
  width: 100%;
  margin: 24px auto;
  padding: 24px;

  background: #ffffff;
  border: 1px solid #eeeff1;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
.form_display {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.writeBulletin_workType label {
  font-size: 18px;
  font-weight: 500;
  display: block;
  margin-left: 30px;
}
.writeBulletin_workType .ant-radio-inner {
  border: 2px solid #e7e9e8 !important;
  width: 20px;
  height: 20px;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #008c8c !important;
}
.writeBulletin_workType .ant-radio-inner::after {
  background-color: var(--main);
  -webkit-transform: scale(2);
          transform: scale(2);
  width: 12px !important;
  height: 12px !important;
  margin: 0;
  -webkit-transform: scale(1) translate(-50%, -50%) !important;
          transform: scale(1) translate(-50%, -50%) !important;
}
.writeBulletin_workDay {
  margin-bottom: 10px;
}
.writeBulletin_workDay label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  margin-right: 15px;
}
.writeBulletin_workDay .ant-row {
  height: 50px;
}
.writeBulletin_workDay .ant-checkbox-inner {
  width: 30px !important;
  height: 30px !important;
  border: 2px solid #e7e9e8;
  border-radius: 9px;
}
.writeBulletin_workDay .ant-checkbox-checked::after {
  border: none !important;
}
.writeBulletin_workDay .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--main);
  border-color: var(--main);
}
.writeBulletin_workDay .ant-checkbox-checked .ant-checkbox-inner::after {
  -webkit-transform: rotate(45deg) scale(1.5) translate(-20%, -60%);
          transform: rotate(45deg) scale(1.5) translate(-20%, -60%);
  margin-left: -1px !important;
}
.writeBulletin_location {
  display: flex;
  justify-content: space-between;
  grid-gap: 30px;
  gap: 30px;
}
.writeBulletin_section .filter_select {
  width: 380px !important;
  /* border: 1px solid #e7e9e8 !important; */
}
.ant-select-selection-item {
  font-weight: 400 !important;
  font-size: 15px !important;
  letter-spacing: -0.24px !important;
  color: #000000 !important;
}
.writeBulletin_section .filter_option {
  font-size: 20px !important;
  font-weight: 500 !important;
}
.writeBulletin_timePicker {
  width: 180px !important;
  border: 1px solid #e7e9e8 !important;
  border-radius: 14px !important;
  height: 48px;
}
.writeBulletin_jins {
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #6d7885;
  display: block;
}
.writeBulletin_description {
  border: 1px solid #e7e9e8 !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  border-radius: 17px !important;
  padding: 10px;
  height: auto !important;
}
.writeResume_universitet__margin {
  margin-bottom: 0 !important;
}
.writeResume_universitet__checked .ant-checkbox {
  top: 0.4em !important;
}
.writeResume_universitet__checked .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #e7e9e8;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #e7e9e8 !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: var(--main) !important;
  border: 2px solid var(--main);
}
.writeResume_universitet__checked .ant-checkbox-checked::after {
  border: none !important;
}
.writeResume_universitet__checked .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--main);
  border-color: var(--main);
}
.writeResume_universitet__checked .ant-checkbox-inner::after {
  margin-left: 0.2px !important;
  margin-top: -0.4px;
}
.writeResume_universitet input {
  border: none !important;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
}
.writeResume_universitet hr {
  border-top: 1px solid #e7e9e8;
  margin: 0;
  padding: 0;
}
.collapse_hr {
  border-top: 1px solid #e7e9e8;
  margin: 0;
  padding: 0;
  margin: -24px 0 20px;
}
.writeResume_footer {
  display: flex;
  justify-content: space-between;
  grid-gap: 40px;
  gap: 40px;
}
.writeBulletin_buttons {
  width: 100%;
  display: flex;
  justify-content: end;
}
.writeBulletin_buttons button {
  width: 200px !important;
  height: 50px;
  border-radius: 17px;
}
.label_p {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #6d7885;
}
.writeBulletin_wotkPrice__inputs {
  width: 180px;
  margin-bottom: 10px !important;
}
.breadcrumb_style {
  display: inline-block;
  margin-top: 32px !important;
}
.breadcrumb_style span {
  font-weight: 500;
  font-size: 16px;
}
.breadcrumb_style a {
  font-weight: 500;
  font-size: 16px;
}
.breadcrumb_style a:hover {
  color: var(--main) !important;
}
.ant-collapse-header {
  padding-left: 0 !important;
}
.collapse_header {
  width: 100%;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #6d7885;
  position: relative;
}
.unchecked_span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #e7e9e8;
}
.checked_span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--main);
  border: 2px solid var(--main);
  display: flex;
  justify-content: center;
  align-items: center;
}
.checked_span svg {
  color: white;
  font-size: 12px;
  font-weight: bold;
}
.ant-checkbox-wrapper span:nth-child(2) {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #6d7885;
}
.required_label {
  position: relative;
}
.required_label::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1;
  content: "*";
}
/* Subtle moving gradient with shadow */

.btn-moving-gradient {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;

  padding: 13px 30px;
  color: #fff;
  cursor: pointer;
  border: none;
  background-size: 300% 100%;
  border-radius: 17px;
  transition: all 0.5s ease-in-out;
}
.btn-moving-gradient:hover {
  background-position: 100% 0;
  transition: all 0.5s ease-in-out;
}
.btn-moving-gradient--red {
  background-image: linear-gradient(to right, hsl(353, 86%, 54%), hsl(393, 86%, 54%), hsl(380, 86%, 54%), #ef233c);
  box-shadow: 0 4px 15px 0 hsl(393, 86%, 54%);
}
.btn-moving-gradient--green {
  background-image: linear-gradient(to right, var(--main), #06b6b6, #05acac, #008c8c);
  box-shadow: 0 4px 10px 0 var(--main);
}
.btn-loading {
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  cursor: initial;
  pointer-events: none;
  opacity: 0.5;
}

.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

/* Responsive section */
@media only screen and (max-width: 1100px) {
  .writeBulletin_section {
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 1100px) {
  .writeBulletin_section {
    margin: 40px auto;
  }
  .writeBulletin_location {
    justify-content: flex-start;
    grid-gap: 40px;
    gap: 40px;
  }
}
@media only screen and (max-width: 960px) {
  .form_container {
    width: 100%;
    margin: 20px auto;
  }
  .form_display {
    flex-direction: column;
  }
  .writeBulletin_location {
    flex-direction: column;
    grid-gap: 0px;
    gap: 0px;
  }
  .half_width {
    width: 100%;
  }
  .writeBulletin_section .filter_select {
    width: 100% !important;
  }
  .writeBulletin_content,
  .writeBulletin_content__right {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .writeBulletin_section {
    margin: 10px auto;
  }
  .container_title {
    margin-top: 20px;
    text-align: center;
  }
}
@media only screen and (max-width: 530px) {
  .writeBulletin_location {
    flex-direction: column;
    grid-gap: 0px;
    gap: 0px;
  }
}
@media only screen and (max-width: 500px) {
  .writeBulletin_section .ant-picker {
    width: 130px !important;
  }
  .writeBulletin_wotkPrice__inputs {
    width: 130px;
  }
  .writeBulletin_workDay label {
    margin-right: 4px;
  }
}

.works_section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
}
.works_content {
  width: 24%;
  flex-grow: 1;
  min-height: 280px;
  background-color: white;
  border-radius: 15px;
  padding: 15px;
  font-size: 18px;
  display: flex;
  flex-wrap: wrap;
  transition: 0.5s;
  max-width: 340px;
}
.works_content:hover {
  box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
}
.works_name__div {
  width: calc(100% - 110px);
}
.works_name {
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.41px;
  color: #000000;
  text-transform: capitalize;

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.works_time {
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  opacity: 0.3;
}
.works_lover__div {
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
}
.works_lover__div svg {
  cursor: pointer;
}
.works_lover__div a {
  display: flex;
}
.works_lover {
  float: right;
  font-size: 25px;
  color: red !important;
  cursor: pointer;
}
.works_price_div {
  display: flex;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin-top: 20px;
}
.works_type__div {
  width: 44%;
  margin-left: 2%;
}
/* .works_price {
  font-size: 22px;
  font-weight: bold;
  margin-top: 7px;
  line-height: 20px;
} */
.works_description__div {
  width: 100%;
  min-height: 85px;
  margin: 12px 0 0px;
  margin-top: 12px;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: -0.24px;
}
.worksfull_about {
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: -0.24px;
  color: #000000;
  opacity: 0.6;
  margin-bottom: 0;
}
.works_footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.works_views {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  text-align: center;

  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #919294;
}
.works_views svg {
  margin-right: 10px;
}
/* Button arrow-slide */
.works-btn {
  margin-right: auto;
  float: left;
  margin-top: auto;
  /* margin-bottom: 5px; */
}
.works-btn-arrow-slide-cont {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-family: inherit;
  width: 160px;
  height: auto;
  font-weight: 550;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
}
.works-btn-arrow-slide-cont:hover {
  background: transparent;
}
.works-btn-arrow-slide-circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.625rem;
  font-weight: 500;
}
.works-btn-arrow-slide-circle .works-btn-arrow-slide-icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}
.works-btn-arrow-slide-circle
  .works-btn-arrow-slide-icon.works-btn-arrow-slide-arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
.works-btn-arrow-slide-arrow::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.works-btn-arrow-slide-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.3rem 0;
  margin: 0 0 0 1.35rem;
  line-height: 30px;
  letter-spacing: 1px;
  text-align: center;
}
.works-btn-arrow-slide-cont:hover .works-btn-arrow-slide-circle {
  width: 100%;
}
.works-btn-arrow-slide-cont:hover
  .works-btn-arrow-slide-circle
  .works-btn-arrow-slide-icon.works-btn-arrow-slide-arrow {
  background: #fff;
  -webkit-transform: translate(1rem, 0);
          transform: translate(1rem, 0);
}
.works-btn-arrow-slide-cont:hover .works-btn-arrow-slide-text {
  color: #fff;
}
.works-btn-arrow-slide-cont--green .works-btn-arrow-slide-circle {
  background: var(--main);
}
.works-btn-arrow-slide-cont--green .works-btn-arrow-slide-text {
  color: var(--main);
}

/* WorksFull secton */
.worksfull_section {
  margin: 50px auto;
  border-radius: 20px;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}
.worksfull_content {
  font-size: 18px;
}
.worksfull_content .works_description__div {
  text-align: justify;
}
.worksfull_details {
  margin-top: 50px;
}
.worksfull_details__content {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.worksfull_details__img {
  margin-right: 20px;
}
.worksfull_details__img img {
  object-fit: cover;
  height: 42px;
}
.worksfull_details__info {
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
}
.worksfull_owner__div {
  margin-top: 60px;
  display: flex;
  align-items: center;
}
.worksfull_owner__rigth {
  display: flex;
  align-items: center;
}
.worksful_owner__img {
  margin-right: 30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  background: #ffffff;
  border: 1px solid #e7e9e8;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
}
.worksfull_call_button {
  border: none;
  background-color: white !important;
  cursor: pointer;
  margin: 0 20px -3px 60px;
}
.worksfull_description__div {
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: -0.24px;
  color: #000000;
  opacity: 0.6;
}
/* Advertize section */
.works_advertize {
  position: relative;
  overflow: hidden;
}
.works_advertize__div {
  position: relative;
  /* display: flex;
  align-items: center; */
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  padding: 15px;
}
.works_advertize__img,
.works_advertize__div {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.works_advertize__img {
  height: auto;
  z-index: 5;
  border-radius: 15px;
  /* width: calc(100% - 30px); */
}
.works_advertize__date {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.38px;
  color: #000000;

  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 12px;
  text-align: center;
}
.works_advertize_description {
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  padding-bottom: 24px;
}
.advertize_section {
  width: 100%;
  background-color: white;
  border-radius: 15px;
  padding: 0px 30px 50px;
  margin-top: 20px;
  min-height: 80vh;
}
.advertize_content {
  width: 60%;
  margin: 40px auto;
}
.advertize_header {
  font-style: 26px;
  font-weight: 700;
  margin: 20px 0;
}
.advertize_text {
  font-style: 19px;
  font-weight: 500;
  margin-top: 30px;
  line-height: 31px;
}
.advertize_img {
  width: 100%;
}
.advertize_img img {
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 1300px) {
  .worksfull_owner__div {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 1000px) {
  .works_content {
    width: 40%;
  }
  .advertize_content {
    width: 94%;
    margin: 40px auto;
  }
}
@media only screen and (max-width: 820px) {
  .works_content {
    width: calc(50% - 20px);
  }
  .works_name {
    width: 100%;
  }
  .worksfull_call_button {
    margin-left: 0;
  }
}
@media only screen and (max-width: 650px) {
  .works_content {
    width: 320px;
  }
  .advertize_section {
    padding: 0px 10px 30px;
  }
}
@media only screen and (max-width: 500px) {
  .advertize_header {
    font-size: 23px;
    margin: 20px 0;
  }
  .advertize_text {
    font-size: 17px;
    margin-top: 30px;
    line-height: 31px;
  }
}

.complain_section {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.complain_modal .ant-modal-content {
  background-color: #f7f8fa;
}
.container_title {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  margin: -10px auto 0;
}
.complain_header__content {
  width: 100%;
  margin: 0 auto;
}
.complain_label {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #6d7885 !important;
  margin-bottom: 12px;
}
.complaint_selections {
  margin: 34px 0 24px;
}
.complain_selector {
  background: #f9f9f9;
  border: 2px solid #e7e9e8;
  border-radius: 100px;
  padding: 16px 20px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.complain_selector_aktive {
  border: 2px solid #ee4719;
}
.complain_selector p {
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 0;
}
.complain_selector span {
  width: 27px;
  height: 27px;
  border: 2px solid #e7e9e8;
  border-radius: 50%;
}
.complain_selector svg {
  height: 27px;
}
.complain_form {
  margin-bottom: 40px;
}
.complain_textarea {
  background: #f9f9f9 !important;
  border: 2px solid #e7e9e8 !important;
  border-radius: 17px !important;
}
.suggestion_breadcrumb__span {
  transition: 0.3s;
}
.suggestion_breadcrumb__span:hover {
  color: #008c8c !important;
}
.ant-rate-star-zero .ant-rate-star-second {
  color: #e7e9e8 !important;
  font-size: 25px;
}
.ant-rate-star-full .ant-rate-star-second {
  color: #fadb14 !important;
  font-size: 25px;
}
.complain_section .editprofile_main_button {
  width: 100%;
  margin-top: 32px;
}
.complain_section button {
  height: 50px;
  width: 100% !important;
}
.full_width {
  width: 100%;
}
.suggestion_title {
  margin-bottom: 31px;
}

.worker_section {
  width: 100%;
}
.worker_section .works_name__div {
  width: 100% !important;
  /* margin-top: 7px; */
}
.worker_section .works_name__div:nth-child(2) {
  margin-top: 22px;
}
.worker_name {
  line-height: 25px !important;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  grid-gap: 8px; 
  gap: 8px;
}
.worker_section .worker_img__div {
  margin-right: 20px;
  display: flex;
  justify-content: center;
} 
.worker_section .works_price_div {
  margin-top: 12px; 
} 
.worker_section .works_description__div {
  margin-top: 9px; 
} 
.worker_section .works_lover__div {
  display: flex;
  justify-content: space-between;
  margin-left: 0;
}
.worker_section .works_lover__div img {
  height: 27px;
}
.worker_img {
  margin: 0 auto;
  width: 52px;
  height: 52px;
  object-fit: cover;
  border-radius: 50%;
} 
.workerfull_section .worksfull_owner__div {
  margin-top: 0 !important;
}
.workerfull_section .works_price_div {
  width: 40%;
}
.workerfull_section .works_lover__div {
  width: 5%;
}
.checkbox_row {
  display: flex;
}
.checkbox_col {
  width: calc(100% / 7);
}
.works_lover__full {
  margin-left: auto;
}
.workerfull_icons{
  display: flex;
  align-items: center;
  grid-gap: 17px;
  gap: 17px;
}
.workerfull_icons svg{
  cursor: pointer;
}
.workerfull_section .worksful_owner__img{
  width: 90px !important;
  height: 90px !important;
}

.login_section {
  width: 120%;
  min-height: 90vh;
  margin-left: -10%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -50px;
}

.login_container {
  width: 456px;
  /* min-height: 480px; */
  background-color: white;
  border-radius: 20px;
  position: relative;
}

.login_section .ant-tabs-tab {
  width: 200px;
  display: flex;
  justify-content: center;
  transition: 0 !important;
}

.login_section .ant-tabs-tab:hover {
  color: var(--main);
}

.login_section .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--main);
}

.login_section .ant-tabs-tab + .ant-tabs-tab {
  margin: 0px;
}

.login_section .ant-tabs-tab-active .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 1px solid var(--main) !important;
}

.ant-tabs-content {
  padding: 20px 40px;
}

.login_section label {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: rgb(129, 126, 126) !important;
}

.login_section .ant-form-item-label {
  padding: 0 !important;
}
.login_section .ant-tabs-tab-btn {
  font-size: 18px !important;
}

.login_section .btn-moving-gradient {
  width: 100%;
}
.login_section .btn-moving-gradient--green {
  box-shadow: none;
}
.login_section .buttons-section {
  width: 100%;
}

.editLogin_content {
  padding: 30px;
  text-align: center;
}

.editLogin_content h2 {
  font-size: 40px;
  font-weight: 500;
  line-height: 40px;
}

.editLogin_content p {
  line-height: 17px;
}

/* .login_section input {
  border-bottom: 1px solid var(--main) !important;
  border-radius: 0 !important;
} */

.aferta_text {
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.07px;
  color: #000000;
  padding-left: 5px;
  padding-top: 6px;
  /* margin-top: -10px; */
}

.login_section .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
}

.login-main h2 {
  width: 80%;
  font-size: 30px;
  line-height: 40px;
  color: var(--text-1);
  font-weight: 600;
  margin-bottom: 16px;
  opacity: 0.8;
}
.login-main-button-reset {
  margin-top: 16px;
  cursor: pointer;
  transition: 0.3s;
}
.login-main-button-reset:hover {
  color: var(--main);
}
.login-main-button-back {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1001;

  background: #ffffff;
  border: 1px solid #edeef0;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);

  position: absolute;
  top: 24px;
  left: -24px;
}

.login-sms-resend {
  display: flex;
  align-items: center;
}
.login-sms-resend button {
  padding-left: 0;
  border: none;
  padding-top: 8px;
}
.login-sms-resend button:hover {
  border: none;
}
.login-form-v {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.otp-input {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}
.otp-input input {
  margin: 0 5px;
  text-align: center;
  line-height: 40px !important;
  font-size: 24px !important;
  border: solid 1px #ccc !important;
  box-shadow: 0 0 5px #ccc inset;
  outline: none;
  width: 20%;
  transition: all 0.2s ease-in-out;
  border-radius: 3px;
}

.otp-input:focus {
  border-color: var(--main);
  box-shadow: 0 0 5px var(--main) inset;
}

.otp-input::selection {
  background: transparent;
}

.notice_section {
  margin-top: 20px;
}

/* Notice modal */
.notice_modal_svg {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}
.notice_modal_svg2 {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.notice_modal_content h2 {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #000000;
  text-align: center;
}
.notice_modal_content p {
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #000000;
}
.notice_modal_content h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.38px;
  color: #343a40;
  text-align: center;
  margin: 20px;
}
.notice_modal_content h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.38px;
  color: #343a40;
  text-align: center;
  margin: 20px;
}

/* Notice setting */
.notice_header {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.notice_header h1 {
  margin-bottom: 0px;
}
.notice_setting_svg {
  font-size: 24px;
  cursor: pointer;
}
.notice_setting_svg svg {
  fill: #000000;
}
.notice_setting {
  border-radius: 20px;
}
.notice_setting .writeBulletin_workDay .ant-form-item-label label {
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 20px !important;
  letter-spacing: -0.24px !important;
  color: #6d7885 !important;
}
.notice_setting .writeBulletin_workDay .ant-checkbox-group label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 12px 20px !important;
}
.notice_setting
  .writeBulletin_workDay
  .ant-checkbox-group
  label
  span:last-child {
  font-size: 17px !important;
  line-height: 20px;
  padding-top: 7px;
}

.notice_setting
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.notice_setting_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  grid-gap: 10px;
  gap: 10px;
}
.notice_setting_header .ant-form-item {
  flex-grow: 1;
}

.notice_setting_header_button {
  width: 48px;
  height: 48px;
  border: 1px solid #e7e9e8;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
}

@media screen and (max-width: 560px) {
  .notice_section {
    width: 100%;
    margin: 80px auto 30px;
  }
  .notice_box {
    width: 100%;
    margin-bottom: 20px;
  }
}

.form {
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.full {
  width: 100%;
}

.half {
  width: calc(50% - 20px);
}

.paying_section {
  padding: 10px;
}
.paying_section p {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: #000000;
  margin-bottom: 23px;
}
.paying_section h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
}
.paying_section .ant-form-vertical .ant-form-item {
  flex-direction: row !important;
}
.paying_types_wrap {
  display: flex;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
  flex-wrap: wrap;
}
.paying_type {
  width: 132px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #333333;
  border-radius: 10px;
  cursor: pointer;
}
.paying_type_active {
  border: 1px solid #e96a00;
}
.paying_form {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 12px;
  gap: 12px;
}
.paying_form2 {
  width: 130px;
  display: inline-block;
}
.paying_form3 {
  width: 90px;
  display: inline-block;
}
.paying_button {
  width: calc(100% - 10px) !important;
  margin: 20px 5px 15px !important;
}
.paying_back_button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1001;

  background: #ffffff;
  border: 1px solid #edeef0;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);

  position: absolute;
  top: 16px;
  left: -20px;
}
/* Tab classes */
.paying_tab .ant-tabs-content {
  padding: 0px;
}
.paying_tab .ant-tabs-nav {
  display: none !important;
  margin: 0;
}
@media only screen and (max-width: 500px) {
  .payment_modal .ant-modal-body {
    padding: 32px 6px 16px !important;
  }
}

.profile_drower_header {
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
  text-align: center;
  color: #000000;
}
.profile_drower_header img {
  float: left;
}
.profile_infroms {
  width: 100%;
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 20px;
  padding: 8px 16px;
  margin: 32px 0 24px;

  font-size: 13px;
  line-height: 16px;
  color: #000000;
}
.profile_informs__img {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin-right: 16px;
}
.profile_informs_right p {
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 4px;
}
.profile_informs_svg {
  width: 72px;
  height: 72px;
  border: 1px solid #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 16px;
}
.profile_informs_right_svg {
  margin-left: auto;
  cursor: pointer;
}
.profile_content {
  width: 100%;
  display: flex;
  align-items: center;
  grid-gap: 18px;
  gap: 18px;
  padding: 22px 21px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #f3f2f8;
}
.profile_content svg {
  min-width: 24px;
}
.profile_content:nth-child(2) {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.profile_content:last-child {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.profile_content__box {
  width: 100%;
  color: black;
  display: flex;
  align-items: flex-end;
  transition: 0.3s;
}
.profile_content__box:hover {
  color: var(--main);
}
.profile_content__box svg path {
  transition: 0.3s;
}
.profile_content__box:hover .profile_contetn__right svg path {
  fill: var(--main) !important;
}
.profile_content__menu {
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  /* color: #000000; */
  cursor: pointer;
}
.ant-modal-confirm-body .anticon-check-circle {
  display: none;
}
.profile_delete__content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: #000000;
}
.profile_delete__content img {
  width: 120px;
  object-fit: cover;
  margin-bottom: 20px;
}
.profile_delete__content p {
  font-size: 20px;
}
.profile_delete__buttons {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}
.profile_delete__buttons button {
  border: 2px solid var(--main);
  width: 160px;
  border-radius: 53px;
  background-color: white;
  padding: 8px 15px;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.38px;
}
.profile_section .profile_delete__buttons button:first-child {
  background-color: var(--main) !important;
  color: white;
}
.profile_delete_button1 {
  background-color: var(--main) !important;
  color: #ffffff;
}
.profile_delete_button2 {
  background-color: white !important;
  color: var(--main) !important;
}
.profile_delete__buttons button:nth-child(2) {
  background-color: var(--main);
  color: white;
}
.profile_delete__buttons button:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}
.ant-modal-content {
  border-radius: 20px !important;
}
.ant-modal-confirm-btns {
  display: none;
}
@media screen and (max-width: 500px) {
  .profile_delete__buttons {
    flex-direction: column;
  }
  .profile_delete__buttons button {
    width: 220px;
  }
}

.editProfile_img {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px auto 24px;
}
.editProfile_img .ant-image {
  width: 121px !important;
  height: 121px !important;
  border-radius: 50%;
}
.editProfile_img .ant-image img {
  border: 7px solid #F7F8FA;
  width: 121px !important;
  height: 121px !important;
  border-radius: 50%;
  object-fit: cover;
}
.editProfile_img .ant-image-mask {
  border-radius: 50%;
}
.editProfile_editSvg {  
  margin-top: auto;
  background-color: #F7F8FA;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  z-index: 200;
  margin-left: -45px;
}
.editProfile_img .ant-upload-list-picture-card-container {
  display: none;
}
.editProfile_img .ant-upload.ant-upload-select-picture-card {
  width: 25px !important;
  height: 121px !important;
  margin-left: 0px;
  margin-bottom: 8px;
  margin-right: 0 !important;
  text-align: center;
  vertical-align: top;
  background-color: #fff !important;
  border: none !important;
  cursor: pointer;
}
.editProfile_img .ant-upload-list {
  width: 25px;
  display: flex;
  justify-content: center;
}
.editProfile_img .ant-tooltip-placement-top {
  padding-bottom: 8px;
  display: none;
}
.editProfile_img .ant-upload-picture-card-wrapper {
  width: 25px !important;
}
.editprofile_main_button{
  width: 100%;
}
.editprofile_main_button button{
  width: 100% !important;
}
.editProfile_change_password{
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px; 
  -webkit-text-decoration-line: underline; 
          text-decoration-line: underline; 
  color: #007CFF;
  cursor: pointer;
}

.navbar_section {
  height: 85px;
  width: 100%;
  background-color: var(--main);
}

.navbar_content {
  max-width: 1400px;
  width: 90%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar_logo {
  width: 150px;
  flex-shrink: 0;
}

.navbar_logo__img {
  width: 100%;
  object-fit: cover;
}

.navbar_section .ant-layout-header {
  width: 740px;
  height: 64px;
  padding: 0 !important;
  color: white;
  line-height: 64px;
  background: var(--main) !important;
}

.navbar_section .ant-menu {
  background: var(--main) !important;
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.navbar_section .ant-menu-horizontal {
  line-height: 0px;
  border: 0;
  border-bottom: 0px solid var(--main);
  box-shadow: none;
}

.navbar_adver__icon {
  height: 18px;
  margin-right: 8px;
  object-fit: cover;
  margin-bottom: 4px;
}

.navbar_adver__icon2 {
  height: 30px;
  margin-right: 12px;
  object-fit: cover;
}

.navbar_toltip__icon {
  margin-left: 7px;
  font-size: 16px !important;
  transition: 0.3s !important;
}
.navbar_toltip__icon svg {
  transition: 0.3s !important;
}
.navbar_toltip__icon svg path {
  transition: 0.3s !important;
}

.navbar_section .ant-menu-title-content {
  font-size: 18px;
}

.navbar_section .ant-menu-title-content span {
  display: flex;
  align-items: center;
}

.navbar_section .ant-menu-title-content span svg {
  margin-right: 5px;
}

.navbar_section .ant-menu-title-content span svg:first-child path {
  stroke: white;
  transition: 0.3s !important;
}

.ant-menu-item:hover .ant-menu-title-content span svg path {
  stroke: var(--hover);
}

.navbar_section .ant-menu-item-active,
.ant-menu-item-selected {
  color: var(--hover) !important;
}

.navbar_section .ant-menu-item-selected svg path {
  stroke: var(--hover) !important;
}

.navbar_section .ant-menu-item-selected a {
  color: var(--hover) !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  padding: 0px 10px !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 0px solid transparent !important;
}

.ant-menu {
  justify-content: flex-end;
}

.ant-tooltip .ant-menu {
  color: var(--main) !important;
  font-size: 16px;
  font-weight: 500;
  justify-content: flex-start !important;
}

.ant-tooltip .ant-menu-item-active,
.ant-menu-item-selected {
  color: var(--hover) !important;
}

.ant-tooltip .ant-menu:not(.ant-meu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-tooltip .ant-menu-item:hover {
  background-color: transparent !important;
}

.ant-tooltip .ant-menu-item:hover .navbar_sublinks {
  color: var(--hover);
}

.ant-tooltip .ant-menu-item:hover .navbar_sublinks svg path {
  fill: var(--hover) !important;
}

.navbar_links {
  color: white !important;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.navbar_links svg path {
  transition: 0.3s;
  stroke: white;
}

.navbar_links:hover {
  color: var(--hover) !important;
}

.ant-menu-item:hover .navbar_links svg path {
  stroke: var(--hover);
}

.navbar_sublinks {
  min-width: 250px;
  color: black !important;
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  transition: 0.3s;
}

.navbar_sublinks svg path {
  fill: black !important;
  opacity: 0.8;
  transition: 0.3s;
}

.navbar_sublinks:hover {
  color: var(--hover) !important;
}

.ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
  border-right-color: transparent !important;
}

.ant-tooltip-inner {
  border-radius: 8px !important;
}

.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px !important;
}

.navbar_right__content {
  width: 100%;
  display: flex;
  align-items: center;
  grid-gap: 9px;
  gap: 9px;
  justify-content: end;
}

.navbar_favourite__icon {
  font-size: 20px;
  color: red !important;
  fill: red !important;
}

.navbar_profile__icon {
  width: 35px;
  height: 35px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-style: 20px;
  color: rgb(233, 233, 233) !important;
  transition: 0.3s;
  border: 1.5px solid white;
  border-radius: 50%;
}
.navbar_profile__icon svg {
  font-size: 18px;
}

.navbar_notice__content {
  width: 35px;
  height: 35px;
  border: 1.5px solid white;
  padding: 6px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar_notice__content img {
  width: 20px;
  object-fit: cover;
}

.navbar_language {
  margin-left: 30px;
}

.navbar_language__content {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  grid-gap: 5px;
  gap: 5px;
}

.navbar_language__span {
  color: white;
}

.navbar_language__box {
  color: rgba(221, 220, 220, 0.763);
  cursor: pointer;
}

.navbar_langiage__aktive {
  color: white;
  font-size: 16px;
  cursor: default;
}

.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 5px 6px !important;
  background-color: white !important;
  color: black !important;
}

.ant-tooltip-arrow-content {
  background-color: white !important;
}

.navbar_main__header {
  z-index: 1;
  width: 70%;
}

.navbar_burger__icon {
  font-size: 27px;
  color: rgb(233, 233, 233) !important;
  transition: 0.3s;
  display: none;
  margin-left: 20px;
}

.navbar_burger {
  display: none;
}

.burger_section {
  margin-top: 20px;
}

.burger_svg path {
  stroke: var(--main);
}

.burger_section .profile_content:first-child {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.burger_section .profile_content:nth-child(2) {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.navbar_main__header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.navbar_main__header ul {
  width: 100%;
}

@media only screen and (max-width: 1450px) {
  .navbar_main__header {
    z-index: 1;
    width: 75%;
  }
}

@media only screen and (max-width: 1350px) {
  .navbar_main__header {
    display: none;
  }

  .navbar_burger__icon {
    display: initial;
  }

  .navbar_burger {
    display: inline;
  }

  .burger_section .ant-menu-title-content {
    color: var(--main) !important;
    font-weight: 500;
    font-size: 20px;
  }

  .burger_section .ant-menu-sub .ant-menu-title-content {
    font-weight: 400;
  }

  .burger_section .ant-menu-item-selected .navbar_links {
    color: #ff4d4f !important;
  }

  .burger_section .ant-menu-item-selected .navbar_sublinks {
    color: #ff4d4f !important;
  }

  .burger_section .ant-menu-submenu-arrow {
    color: var(--main) !important;
  }

  .burger_section .ant-menu-item-selected .anticon {
    color: #ff4d4f !important;
  }

  .navbar_links {
    color: var(--main) !important;
    font-size: 20px;
  }

  .navbar_sublinks {
    color: var(--main) !important;
  }

  .burger_section .anticon {
    color: var(--main) !important;
    font-weight: 500;
    font-size: 20px !important;
  }

  .burger_section .ant-menu-item:hover .anticon {
    color: #ff4d4f !important;
  }

  .navbar_links:hover {
    color: #ff4d4f !important;
  }

  .navbar_sublinks:hover {
    color: #ff4d4f !important;
  }
}

@media only screen and (max-width: 500px) {
  .navbar_section {
    height: 85px;
  }

  .navbar_logo {
    width: 125px;
  }

  .navbar_burger__icon {
    margin-left: 5px;
  }

  .navbar_language {
    margin-left: 5px;
  }
  .navbar_language__content {
    font-size: 12px;
  }

  .navbar_langiage__aktive {
    font-size: 13px;
  }

  .navbar_notice__content img {
    width: 15px;
  }

  .navbar_profile__icon {
    font-weight: bold;
    font-style: 14px;
    padding: 6px;
  }
  .navbar_profile__icon svg {
    font-size: 16px;
  }
}

.advertise_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  padding-top: 60px;
}
.advertise_section p {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: -5px;
  margin-top: 10px;
}
/* .advertise_section .input-range__slider:first-child {
  display: none !important;
} */
.advertise_section .input-range__slider {
  background: none;
  border: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAArFJREFUWEftlktoU1EQhmdyE1O0WKkoVfBJU5OASnFRBSkxKQoKgmKl7b1CQXTjY6EbkYJSF6KgiyouFIrYm/ioOxFEyQNrEVz4QpNII10oFKkgIlVDkjtOIimxeZyTaFqQ3s0lnP/8/5eZzDlBmOEHZzgfZgFmK/D/ViCypWs3gslsD+p3Sk1ayQq8c3VuJlQ2GUnQnUP6mMzIRtrUtUB4lrU7gIjsAZsZ4bRRbG9JgLBbG0OEBgBKsekgEl22B73DhcxirR3LEmalFxC7s+sENOL0e5sqrgAD3GeA7X8YELxhoL4awzKwKnT950iLOj9RCz0IeJR11lwtEpyzB/QTFQNEPeoeAhwsbEBfee0uw+zi8PpCGiVlNDeFfC8rBqANBy3RBRPjAFgn0/9cDRHEnAHdJtonHMOIW+3jvh4RGeWvU6/D7z0l2icEiHo61xEor0RGU9ctKbA1hvSYaJ8QIG0Q9mjPWdgsMptcJ3jtCOjrZfRSABG3dpjvzUsyhhmNQScdQW/6LBA+UgDv29rr4oZ1nEfSInRkgSWRXN74+NYHGa0UQKYNbu02A+wVmfLh98wZ8LaIdNl1aQA+27eByfRAwvi4w69flNBlJNIAxFo+mD7ya2lRc/76VjSWrPbf/PTPAX63QT2DiD3FzBlyyOnXW2XDy6pAWhxxdawExTxaHIAO8eVzpWoAGQiPFuSXKz+EUnPjtGjFE9+XqgKE3V37EE038kIIHvHhs7Wc8LJbkN4w6uqu+aEkP/Ovd15uGBq0n/8r9FcdINMGt3qVL6gD2TC++RKm+JyF9uH+b9MCEHapG1HBp5NhBPe4/DvLDa+oBdkQrkKUq7Am89kglc9+3/QCeLRjHHiBZ/97vYKLGx4OTEwrwFtXey2arC8A6RrP/vlKwv+qBZUGTt33C2Ao0SG+lg7PAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: cover;
  width: 28px;
  height: 28px;
  margin-bottom: 20px;
  position: absolute;
  top: -16px;
  left: -6px;
}
.advertise_section .input-range__label--value {
  position: absolute;
  top: -2.8rem;
}
.advertise_section .input-range__track--active {
  background: #d7435c !important;
}
.advertise_section .input-range__track {
  background: var(--main);
}
.advertise_section .input-range__label-container {
  font-size: 18px;
  font-weight: 400;
}
.advertise_section .input-range__label--min .input-range__label-container {
  display: none;
}
.advertise_section .input-range__label--max .input-range__label-container {
  display: none;
}
.advertize_ranges {
  width: 350px;
}
.advertize_ranges p {
  margin-bottom: 30px;
}

.advert_share {
  width: 100%;
  height: calc(68vh - 167px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.advert_share__box {
  width: 350px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  width: 90vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center; 
  position: relative;
  left: 6vmin;
  text-align: center;
  margin: 0 auto;
}

.cog-wheel1,
.cog-wheel2 {
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
}
.cog-wheel2 {
  z-index: 10;
}
.cog1,
.cog2 {
  width: 40vmin;
  height: 40vmin;
  border-radius: 50%;
  border: 6vmin solid #f3c623;
  position: relative;
  -webkit-animation-duration: 16s;
          animation-duration: 16s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.cog1 {
  -webkit-animation-name: rotate;
          animation-name: rotate;
}
.cog2 {
  -webkit-animation-name: reverse;
          animation-name: reverse;
}
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate {
  from {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes reverse {
  from {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@keyframes reverse {
  from {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
.cog2 {
  border: 6vmin solid #4f8a8b;
}

.top,
.down,
.left,
.right,
.left-top,
.left-down,
.right-top,
.right-down {
  width: 10vmin;
  height: 10vmin;
  background-color: #f3c623;
  position: absolute;
}

.cog2 .top,
.cog2 .down,
.cog2 .left,
.cog2 .right,
.cog2 .left-top,
.cog2 .left-down,
.cog2 .right-top,
.cog2 .right-down {
  background-color: #4f8a8b;
}

.top {
  top: -14vmin;
  left: 9vmin;
}

.down {
  bottom: -14vmin;
  left: 9vmin;
}

.left {
  left: -14vmin;
  top: 9vmin;
}

.right {
  right: -14vmin;
  top: 9vmin;
}

.left-top {
  -webkit-transform: rotateZ(-45deg);
          transform: rotateZ(-45deg);
  left: -8vmin;
  top: -8vmin;
}

.left-down {
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
  left: -8vmin;
  top: 25vmin;
}

.right-top {
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
  right: -8vmin;
  top: -8vmin;
}

.right-down {
  -webkit-transform: rotateZ(-45deg);
          transform: rotateZ(-45deg);
  right: -8vmin;
  top: 25vmin;
}

.cog2 {
  position: relative;
  left: -10.2vmin;
  bottom: 10vmin;
}

h1 {
  color: #142833; 
}

.first-four {
  position: relative;
  left: 6vmin;
  font-size: 40vmin;
}

.second-four {
  position: relative;
  right: 18vmin;
  font-size: 40vmin;
}

.wrong-para { 
  position: absolute;
  bottom: 15vmin;
  padding: 3vmin 12vmin 3vmin 3vmin;
  font-weight: 600;
  color: #092532;
}

.footer_section {
  background-color: var(--main);
  width: 100%;
  min-height: 32vh;
}

.footer_box {
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
  padding-top: 60px;
}

.footer_content {
  width: 100%;
  display: flex;
  grid-gap: 5%;
  gap: 5%;
  color: #eff8f8;
}

.footer_content h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 50px;
  color: #eff8f8;
  margin-bottom: 30px;
}

.footer_informs {
  width: 20%;

  font-weight: 500;
  line-height: 20px;
}

.footer_informs p {
  margin-top: 40px;
}

.footer_connection {
  width: 20%;
}

.footer_connection ul {
  list-style: none;
  padding-left: 0;
}

.footer_connection ul li {
  line-height: 15px;
  display: flex;
  transition: 0.5s;
  cursor: copy;
}

.footer_connection ul li:hover {
  color: var(--hover);
}

.footer_connection svg {
  width: 20px;
}

.footer_app__icons {
  margin-top: -55px;
  transition: 1s;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.footer_app__icons_img {
  width: 270px;
  object-fit: cover;
  cursor: pointer;
}

.footer_app__icons img:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.footer_bottom {
  border-top: 0.5px solid #c1c1c16e;
  margin-top: 20px;
  padding-top: 20px;
  color: white;
  font-size: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
}

.footer_bottom span {
  width: 22px;
  height: 22px;
  padding: 2px;
  border: 0.5px solid white;
  border-radius: 50%;
  font-size: 10px;
}

.footer_bottom a {
  color: rgb(0, 183, 255);
  color: white;
  font-size: 17px;
  text-decoration: underline;
  line-height: 17px;
  margin-left: 10px;
}

.footer_bottom a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1100px) {
  .footer_content {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer_informs,
  .footer_connection {
    width: 40%;
  }

  .footer_app__content {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
}

@media screen and (max-width: 600px) {
  .footer_content {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer_box {
    padding-bottom: 100px;
  }

  .footer_informs,
  .footer_connection {
    width: 100%;
  }

  .footer_app__content {
    text-align: start;
    margin-top: 10px;
  }

  .footer_content h3 {
    font-size: 20px;
    line-height: 50px;
    margin-bottom: 10px;
  }

  .footer_app__content h3 {
    line-height: 30px;
  }

  .footer_app__icons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer_app__icons img {
    margin-bottom: -30px;
  }

  .footer_bottom {
    margin-top: 40px;
  }
}
.chat_section {
  height: 80vh;
  display: flex;
  margin-top: 16px;
}
.chat_content {
  width: 100%;
  /* border: 1px solid black; */
  border-radius: 10px;
  display: flex;
}

/* Chat list section */
.chat_left {
  width: 30%;
  background-color: white;
  border-radius: 30px 0 0 30px;
  min-width: 320px;
  box-sizing: border-box;
  border-right: 1px solid #cad4de;
  padding: 0 4px;
}
.chatlist_header {
  width: 100%;
  height: 80px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  border-top-left-radius: 30px;
}
.chatlist_header_input {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  margin: 0 16px;
  border-radius: 5px;

  background: #f0f0f0;
  opacity: 0.9;
  border-radius: 10px;
  padding: 12px 25px;
}
.chatlist_header input {
  border: none !important;
}
.chatlist_header svg {
  cursor: pointer;
}
.chatlist {
  width: 100%;
}
.chatlist_content {
  height: calc(80vh - 80px);
  overflow: auto;
  border-bottom-left-radius: 30px;
}
.chatlist_content:hover.chatlist_content::-webkit-scrollbar-thumb {
  background: #a4abb5;
  border-radius: 10px;
}
.chatlist_content::-webkit-scrollbar {
  width: 5px;
}
.chatlist_content::-webkit-scrollbar-track {
  background-color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.chatlist_content::-webkit-scrollbar-thumb {
  background: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.chatlist_div {
  height: 70px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 10px;
}
.chatlist_div:hover {
  background-color: #f4f9fa;
}
.chatlist_div_active {
  background-color: #dce3e9;
}
.chatlist_div_active:hover {
  background-color: #dce3e9;
}

.chatlist_img {
  position: relative;
}
.chatlist_img_online::after {
  content: " ";
  width: 10.67px;
  height: 10.67px;
  background-color: #4cd964;
  border: 2px solid white;
  border-radius: 50%;
  position: absolute;
  right: 3.7px;
  bottom: 3.7px;
}
.chatlist_img img {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
}
.chatlist_name {
  margin-left: 12px;
  flex-grow: 1;
}
.chatlist_name h3 {
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
}
/* .chatlist_div_active .chatlist_name h3 {
  color: white;
} */
.chatlist_name p {
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  opacity: 0.6;
}
.chatlist_date p {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  opacity: 0.4;
  margin-bottom: 4px;
}
.chatlist_div_active .chatlist_date_p {
  color: white;
  opacity: 0.6;
}
.chatlist_date span {
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: auto;

  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.07px;
  color: #ffffff;
}

.chatlist_aktive {
  background-color: var(--main);
}
.chatlist_div_active .chatlist_aktive {
  color: var(--main);
  background-color: #ffffff;
  /* border: 1px solid white; */
}

/* Chat Message section */
.chatmessage_empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatmessage_empty span {
  padding: 8px 30px;
  background-color: var(--main);
  color: white;
  font-size: 18px;
  border-radius: 30px;
}
.chat_right {
  width: 70%;
  flex-grow: 1;
  background-color: white;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.chatMessage {
  width: 100%;
  height: 100%;
  background-color: white;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.chatmessage_header {
  height: 80px;
  padding: 25px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f9fa;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #252a31;
  border-top-right-radius: 30px;
  border-bottom: 1px solid #cad4de;
}
.chatmessage_divider {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  color: #000000;
  opacity: 0.5;
}
.ant-divider-horizontal.ant-divider-with-text {
  border-top-color: rgba(0, 0, 0, 0.3) !important;
}
.chatmessage_content {
  padding: 16px 16px 0 16px;
  height: calc(100% - 175px);
  overflow: auto;
  transition: 0.3s;
}
/* .chatmessage_content:hover.chatmessage_content::-webkit-scrollbar-track {
  background-color: #dadada;
} */
.chatmessage_content:hover.chatmessage_content::-webkit-scrollbar-thumb {
  background: #a4abb5;
  border-radius: 10px;
}
.chatmessage_content::-webkit-scrollbar {
  width: 5px;
}
.chatmessage_content::-webkit-scrollbar-track {
  background-color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.chatmessage_content::-webkit-scrollbar-thumb {
  background: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.chatmessage_content_div {
  margin-bottom: 10px;
  position: relative;
}
.chatmessage_content_date_wrap {
  display: flex;
  justify-content: center;
}
.chatmessage_content_date {
  margin: 15px auto;
  background-color: #e5eaef;
  padding: 4px 15px;
  border-radius: 15px;
  font-weight: 500;
  font-size: 12px;
  color: #4f5e71; 
}
.chatmessage_mine {
  text-align: end;
}
.chatmessage_wrap {
  display: inline-block;
}
.chatmessage_notmine .chatmessage_wrap {
  display: flex;
  align-items: flex-start;
  grid-gap: 10px;
  gap: 10px;
}
.chatmessage_person_img {
  display: inline-block;
  height: 100%;
}
.chatmessage_person_img img {
  display: inline-block;
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.chatmessage_person_img_name {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #41a4a6;
  background-color: #c7d4e8;

  color: white;
  font-size: 18px;
  font-family: cursive;
}
.chatlist_person_img_name {
  width: 48px;
  height: 48px;
  font-size: 24px;
  font-weight: 500;
}
.chatmessage_box {
  min-width: 160px;
  max-width: 300px;
  padding: 12px 16px 0;
  box-shadow: 0 0 2px rgb(37 42 49 / 16%), 0 1px 4px rgb(37 42 49 / 12%);
}
.chatmessage_box__edited {
  margin-right: 5px;
}
.chatmessage_box p:first-child {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 3px;
  margin-left: 5px;
  overflow: hidden;
  padding-right: 6px;
}
.chatmessage_box p:nth-child(2) {
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.07px;
  color: #000000;
  opacity: 0.3;
  margin-left: 5px;
  margin-bottom: 2px;
  text-align: end;
  margin-right: -5px;
}
.chatmessage_isread span:first-child {
  margin-left: 5px;
}
.chatmessage_isread span:nth-child(2) {
  margin-left: -12px;
}
.chatmessage_isread span svg {
  fill: #3b6d70;
  font-size: 14px;
}
.chatmessage_notmine .chatmessage_isread {
  display: none;
}
.chatmessage_mine .chatmessage_box {
  background: #e8f4fd;
  box-shadow: 0 0 2px rgb(37 42 49 / 16%), 0 1px 4px rgb(37 42 49 / 12%);
  border-radius: 10px 10px 0px 10px;
  text-align: start;
}
.chatmessage_notmine .chatmessage_box {
  background-color: #fff;
  border-radius: 10px 10px 10px 0px;
}
.chatmessage_footer {
  background-color: #f4f9fa;
  padding: 16px;
  border-bottom-right-radius: 30px;
}
.chatmessage_write {
  background: #ffffff;
  border-radius: 20px;
  padding: 7.5px 19.5px;
  display: flex;
  align-items: center;
}
.chatmessage_write span {
  padding-right: 12px;
  margin-right: 12px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatmessage_write input {
  border: none !important;
  outline: none;
  flex-grow: 1;
  margin-right: 12px;
}
.chatmessage_write svg {
  cursor: pointer;
}
.chatmessage_more {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 16px;
}
.chatmessage_more_content {
  background: #ffffff;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.14);
  border-radius: 15px;
}
.chatmessage_more_box {
  display: flex;
  align-items: center;
  grid-gap: 11px;
  gap: 11px;
  padding: 8px 10px;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;
  border-radius: 15px;
}
.chatmessage_more_box:hover {
  background-color: #f4f9fa;
}
.chatmessage_more_box svg {
  width: 18px;
  height: 18px;
}
.chatmessage_more_box svg path {
  stroke: #000000;
  opacity: 0.9;
}
.chatmessage_confirm_modal .ant-modal-body {
  padding: 24px !important;
}
.chatmessage_confirm_modal .ant-modal-content {
  width: 400px;
}
.chatmessage_confirm_modal h2 span {
  font-weight: bold;
}
.chatmessage_confirm_buttons {
  display: flex;
  justify-content: flex-end;
  grid-gap: 16px;
  gap: 16px;
}
.chatmessage_hasmore {
  display: flex;
}
.chatmessage_hasmore_button {
  margin: 0px auto 20px;
  -webkit-transform: rotateZ(-90deg);
          transform: rotateZ(-90deg);
  cursor: pointer;
  font-size: 24px;
  color: #41a4a6 !important;
  transition: 0.3s;
}
.chatmessage_hasmore_button:hover {
  color: var(--main) !important;
}
.chatmessage_loading_left {
  display: flex;
  justify-content: flex-end;
}
.chatmessage_loading_mine {
  width: 200px !important;
  height: 55px !important;
  margin-bottom: 10px;
  text-align: end;
  border-radius: 15px 15px 0px 15px;
}
.chatmessage_loading_notmine {
  width: 200px !important;
  height: 55px !important;
  border-radius: 15px 15px 15px 0px;
  margin-left: 5px;
}
.chatmessage_ending_ref {
  /* display: none; */
  /* border: none !important;
  outline: none !important; */
  -webkit-transform: rotatex(90deg) !important;
          transform: rotatex(90deg) !important;
  height: 1px !important;
  width: 1px !important;
}

/* Toast section */
.chatmessage_toast_div {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  overflow: hidden;
  padding: 2px;
}
.chatmessage_toast_img img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.chatmessage_toast_info h3 {
  font-weight: 700;
}
.chatmessage_toast_info p {
  margin-bottom: 0;
}
.chatmessage_toast .Toastify__toast-body {
  padding: 0;
}
.chatmessage_toast .Toastify__toast-icon {
  display: none;
}

.chat_breadcrumb_visible {
  display: none;
}
.chat_icon_color path {
  stroke: #919294;
}
@media only screen and (max-width: 1000px) {
  .chat_right {
    width: calc(100% - 320px);
  }
}
@media only screen and (max-width: 850px) {
  .chat_visible {
    display: none;
  }
  .chat_breadcrumb_visible {
    display: initial;
  }
  .chat_left {
    width: 500px;
    border-radius: 30px;
    min-width: 320px;
  }
  .chat_right {
    width: 100%;
    border-radius: 30px;
  }
  .chatMessage {
    border-radius: 30px;
  }
  .chatmessage_header {
    border-top-left-radius: 30px;
  }
  .chatmessage_footer {
    border-bottom-left-radius: 30px;
  }
  .chatlist_header {
    border-top-right-radius: 30px;
  }
}

