.works_section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}
.works_content {
  width: 24%;
  flex-grow: 1;
  min-height: 280px;
  background-color: white;
  border-radius: 15px;
  padding: 15px;
  font-size: 18px;
  display: flex;
  flex-wrap: wrap;
  transition: 0.5s;
  max-width: 340px;
}
.works_content:hover {
  -webkit-box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
}
.works_name__div {
  width: calc(100% - 110px);
}
.works_name {
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.41px;
  color: #000000;
  text-transform: capitalize;

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.works_time {
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  opacity: 0.3;
}
.works_lover__div {
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
}
.works_lover__div svg {
  cursor: pointer;
}
.works_lover__div a {
  display: flex;
}
.works_lover {
  float: right;
  font-size: 25px;
  color: red !important;
  cursor: pointer;
}
.works_price_div {
  display: flex;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin-top: 20px;
}
.works_type__div {
  width: 44%;
  margin-left: 2%;
}
/* .works_price {
  font-size: 22px;
  font-weight: bold;
  margin-top: 7px;
  line-height: 20px;
} */
.works_description__div {
  width: 100%;
  min-height: 85px;
  margin: 12px 0 0px;
  margin-top: 12px;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: -0.24px;
}
.worksfull_about {
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: -0.24px;
  color: #000000;
  opacity: 0.6;
  margin-bottom: 0;
}
.works_footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.works_views {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  text-align: center;

  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #919294;
}
.works_views svg {
  margin-right: 10px;
}
/* Button arrow-slide */
.works-btn {
  margin-right: auto;
  float: left;
  margin-top: auto;
  /* margin-bottom: 5px; */
}
.works-btn-arrow-slide-cont {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-family: inherit;
  width: 160px;
  height: auto;
  font-weight: 550;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
}
.works-btn-arrow-slide-cont:hover {
  background: transparent;
}
.works-btn-arrow-slide-circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.625rem;
  font-weight: 500;
}
.works-btn-arrow-slide-circle .works-btn-arrow-slide-icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}
.works-btn-arrow-slide-circle
  .works-btn-arrow-slide-icon.works-btn-arrow-slide-arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
.works-btn-arrow-slide-arrow::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}
.works-btn-arrow-slide-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.3rem 0;
  margin: 0 0 0 1.35rem;
  line-height: 30px;
  letter-spacing: 1px;
  text-align: center;
}
.works-btn-arrow-slide-cont:hover .works-btn-arrow-slide-circle {
  width: 100%;
}
.works-btn-arrow-slide-cont:hover
  .works-btn-arrow-slide-circle
  .works-btn-arrow-slide-icon.works-btn-arrow-slide-arrow {
  background: #fff;
  transform: translate(1rem, 0);
}
.works-btn-arrow-slide-cont:hover .works-btn-arrow-slide-text {
  color: #fff;
}
.works-btn-arrow-slide-cont--green .works-btn-arrow-slide-circle {
  background: var(--main);
}
.works-btn-arrow-slide-cont--green .works-btn-arrow-slide-text {
  color: var(--main);
}

/* WorksFull secton */
.worksfull_section {
  margin: 50px auto;
  border-radius: 20px;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}
.worksfull_content {
  font-size: 18px;
}
.worksfull_content .works_description__div {
  text-align: justify;
}
.worksfull_details {
  margin-top: 50px;
}
.worksfull_details__content {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.worksfull_details__img {
  margin-right: 20px;
}
.worksfull_details__img img {
  object-fit: cover;
  height: 42px;
}
.worksfull_details__info {
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
}
.worksfull_owner__div {
  margin-top: 60px;
  display: flex;
  align-items: center;
}
.worksfull_owner__rigth {
  display: flex;
  align-items: center;
}
.worksful_owner__img {
  margin-right: 30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  background: #ffffff;
  border: 1px solid #e7e9e8;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
}
.worksfull_call_button {
  border: none;
  background-color: white !important;
  cursor: pointer;
  margin: 0 20px -3px 60px;
}
.worksfull_description__div {
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: -0.24px;
  color: #000000;
  opacity: 0.6;
}
/* Advertize section */
.works_advertize {
  position: relative;
  overflow: hidden;
}
.works_advertize__div {
  position: relative;
  /* display: flex;
  align-items: center; */
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  padding: 15px;
}
.works_advertize__img,
.works_advertize__div {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.works_advertize__img {
  height: auto;
  z-index: 5;
  border-radius: 15px;
  /* width: calc(100% - 30px); */
}
.works_advertize__date {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.38px;
  color: #000000;

  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 12px;
  text-align: center;
}
.works_advertize_description {
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  padding-bottom: 24px;
}
.advertize_section {
  width: 100%;
  background-color: white;
  border-radius: 15px;
  padding: 0px 30px 50px;
  margin-top: 20px;
  min-height: 80vh;
}
.advertize_content {
  width: 60%;
  margin: 40px auto;
}
.advertize_header {
  font-style: 26px;
  font-weight: 700;
  margin: 20px 0;
}
.advertize_text {
  font-style: 19px;
  font-weight: 500;
  margin-top: 30px;
  line-height: 31px;
}
.advertize_img {
  width: 100%;
}
.advertize_img img {
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 1300px) {
  .worksfull_owner__div {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 1000px) {
  .works_content {
    width: 40%;
  }
  .advertize_content {
    width: 94%;
    margin: 40px auto;
  }
}
@media only screen and (max-width: 820px) {
  .works_content {
    width: calc(50% - 20px);
  }
  .works_name {
    width: 100%;
  }
  .worksfull_call_button {
    margin-left: 0;
  }
}
@media only screen and (max-width: 650px) {
  .works_content {
    width: 320px;
  }
  .advertize_section {
    padding: 0px 10px 30px;
  }
}
@media only screen and (max-width: 500px) {
  .advertize_header {
    font-size: 23px;
    margin: 20px 0;
  }
  .advertize_text {
    font-size: 17px;
    margin-top: 30px;
    line-height: 31px;
  }
}
