.chat_section {
  height: 80vh;
  display: flex;
  margin-top: 16px;
}
.chat_content {
  width: 100%;
  /* border: 1px solid black; */
  border-radius: 10px;
  display: flex;
}

/* Chat list section */
.chat_left {
  width: 30%;
  background-color: white;
  border-radius: 30px 0 0 30px;
  min-width: 320px;
  box-sizing: border-box;
  border-right: 1px solid #cad4de;
  padding: 0 4px;
}
.chatlist_header {
  width: 100%;
  height: 80px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  border-top-left-radius: 30px;
}
.chatlist_header_input {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 0 16px;
  border-radius: 5px;

  background: #f0f0f0;
  opacity: 0.9;
  border-radius: 10px;
  padding: 12px 25px;
}
.chatlist_header input {
  border: none !important;
}
.chatlist_header svg {
  cursor: pointer;
}
.chatlist {
  width: 100%;
}
.chatlist_content {
  height: calc(80vh - 80px);
  overflow: auto;
  border-bottom-left-radius: 30px;
}
.chatlist_content:hover.chatlist_content::-webkit-scrollbar-thumb {
  background: #a4abb5;
  border-radius: 10px;
}
.chatlist_content::-webkit-scrollbar {
  width: 5px;
}
.chatlist_content::-webkit-scrollbar-track {
  background-color: white;
  transition: 0.3s;
}
.chatlist_content::-webkit-scrollbar-thumb {
  background: white;
  transition: 0.3s;
}
.chatlist_div {
  height: 70px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 10px;
}
.chatlist_div:hover {
  background-color: #f4f9fa;
}
.chatlist_div_active {
  background-color: #dce3e9;
}
.chatlist_div_active:hover {
  background-color: #dce3e9;
}

.chatlist_img {
  position: relative;
}
.chatlist_img_online::after {
  content: " ";
  width: 10.67px;
  height: 10.67px;
  background-color: #4cd964;
  border: 2px solid white;
  border-radius: 50%;
  position: absolute;
  right: 3.7px;
  bottom: 3.7px;
}
.chatlist_img img {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
}
.chatlist_name {
  margin-left: 12px;
  flex-grow: 1;
}
.chatlist_name h3 {
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
}
/* .chatlist_div_active .chatlist_name h3 {
  color: white;
} */
.chatlist_name p {
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  opacity: 0.6;
}
.chatlist_date p {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  opacity: 0.4;
  margin-bottom: 4px;
}
.chatlist_div_active .chatlist_date_p {
  color: white;
  opacity: 0.6;
}
.chatlist_date span {
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: auto;

  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.07px;
  color: #ffffff;
}

.chatlist_aktive {
  background-color: var(--main);
}
.chatlist_div_active .chatlist_aktive {
  color: var(--main);
  background-color: #ffffff;
  /* border: 1px solid white; */
}

/* Chat Message section */
.chatmessage_empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatmessage_empty span {
  padding: 8px 30px;
  background-color: var(--main);
  color: white;
  font-size: 18px;
  border-radius: 30px;
}
.chat_right {
  width: 70%;
  flex-grow: 1;
  background-color: white;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.chatMessage {
  width: 100%;
  height: 100%;
  background-color: white;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.chatmessage_header {
  height: 80px;
  padding: 25px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f9fa;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #252a31;
  border-top-right-radius: 30px;
  border-bottom: 1px solid #cad4de;
}
.chatmessage_divider {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  color: #000000;
  opacity: 0.5;
}
.ant-divider-horizontal.ant-divider-with-text {
  border-top-color: rgba(0, 0, 0, 0.3) !important;
}
.chatmessage_content {
  padding: 16px 16px 0 16px;
  height: calc(100% - 175px);
  overflow: auto;
  transition: 0.3s;
}
/* .chatmessage_content:hover.chatmessage_content::-webkit-scrollbar-track {
  background-color: #dadada;
} */
.chatmessage_content:hover.chatmessage_content::-webkit-scrollbar-thumb {
  background: #a4abb5;
  border-radius: 10px;
}
.chatmessage_content::-webkit-scrollbar {
  width: 5px;
}
.chatmessage_content::-webkit-scrollbar-track {
  background-color: white;
  transition: 0.3s;
}
.chatmessage_content::-webkit-scrollbar-thumb {
  background: white;
  transition: 0.3s;
}
.chatmessage_content_div {
  margin-bottom: 10px;
  position: relative;
}
.chatmessage_content_date_wrap {
  display: flex;
  justify-content: center;
}
.chatmessage_content_date {
  margin: 15px auto;
  background-color: #e5eaef;
  padding: 4px 15px;
  border-radius: 15px;
  font-weight: 500;
  font-size: 12px;
  color: #4f5e71; 
}
.chatmessage_mine {
  text-align: end;
}
.chatmessage_wrap {
  display: inline-block;
}
.chatmessage_notmine .chatmessage_wrap {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.chatmessage_person_img {
  display: inline-block;
  height: 100%;
}
.chatmessage_person_img img {
  display: inline-block;
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.chatmessage_person_img_name {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #41a4a6;
  background-color: #c7d4e8;

  color: white;
  font-size: 18px;
  font-family: cursive;
}
.chatlist_person_img_name {
  width: 48px;
  height: 48px;
  font-size: 24px;
  font-weight: 500;
}
.chatmessage_box {
  min-width: 160px;
  max-width: 300px;
  padding: 12px 16px 0;
  box-shadow: 0 0 2px rgb(37 42 49 / 16%), 0 1px 4px rgb(37 42 49 / 12%);
}
.chatmessage_box__edited {
  margin-right: 5px;
}
.chatmessage_box p:first-child {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 3px;
  margin-left: 5px;
  overflow: hidden;
  padding-right: 6px;
}
.chatmessage_box p:nth-child(2) {
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.07px;
  color: #000000;
  opacity: 0.3;
  margin-left: 5px;
  margin-bottom: 2px;
  text-align: end;
  margin-right: -5px;
}
.chatmessage_isread span:first-child {
  margin-left: 5px;
}
.chatmessage_isread span:nth-child(2) {
  margin-left: -12px;
}
.chatmessage_isread span svg {
  fill: #3b6d70;
  font-size: 14px;
}
.chatmessage_notmine .chatmessage_isread {
  display: none;
}
.chatmessage_mine .chatmessage_box {
  background: #e8f4fd;
  box-shadow: 0 0 2px rgb(37 42 49 / 16%), 0 1px 4px rgb(37 42 49 / 12%);
  border-radius: 10px 10px 0px 10px;
  text-align: start;
}
.chatmessage_notmine .chatmessage_box {
  background-color: #fff;
  border-radius: 10px 10px 10px 0px;
}
.chatmessage_footer {
  background-color: #f4f9fa;
  padding: 16px;
  border-bottom-right-radius: 30px;
}
.chatmessage_write {
  background: #ffffff;
  border-radius: 20px;
  padding: 7.5px 19.5px;
  display: flex;
  align-items: center;
}
.chatmessage_write span {
  padding-right: 12px;
  margin-right: 12px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatmessage_write input {
  border: none !important;
  outline: none;
  flex-grow: 1;
  margin-right: 12px;
}
.chatmessage_write svg {
  cursor: pointer;
}
.chatmessage_more {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 16px;
}
.chatmessage_more_content {
  background: #ffffff;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.14);
  border-radius: 15px;
}
.chatmessage_more_box {
  display: flex;
  align-items: center;
  gap: 11px;
  padding: 8px 10px;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;
  border-radius: 15px;
}
.chatmessage_more_box:hover {
  background-color: #f4f9fa;
}
.chatmessage_more_box svg {
  width: 18px;
  height: 18px;
}
.chatmessage_more_box svg path {
  stroke: #000000;
  opacity: 0.9;
}
.chatmessage_confirm_modal .ant-modal-body {
  padding: 24px !important;
}
.chatmessage_confirm_modal .ant-modal-content {
  width: 400px;
}
.chatmessage_confirm_modal h2 span {
  font-weight: bold;
}
.chatmessage_confirm_buttons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
.chatmessage_hasmore {
  display: flex;
}
.chatmessage_hasmore_button {
  margin: 0px auto 20px;
  transform: rotateZ(-90deg);
  cursor: pointer;
  font-size: 24px;
  color: #41a4a6 !important;
  transition: 0.3s;
}
.chatmessage_hasmore_button:hover {
  color: var(--main) !important;
}
.chatmessage_loading_left {
  display: flex;
  justify-content: flex-end;
}
.chatmessage_loading_mine {
  width: 200px !important;
  height: 55px !important;
  margin-bottom: 10px;
  text-align: end;
  border-radius: 15px 15px 0px 15px;
}
.chatmessage_loading_notmine {
  width: 200px !important;
  height: 55px !important;
  border-radius: 15px 15px 15px 0px;
  margin-left: 5px;
}
.chatmessage_ending_ref {
  /* display: none; */
  /* border: none !important;
  outline: none !important; */
  transform: rotatex(90deg) !important;
  height: 1px !important;
  width: 1px !important;
}

/* Toast section */
.chatmessage_toast_div {
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  padding: 2px;
}
.chatmessage_toast_img img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.chatmessage_toast_info h3 {
  font-weight: 700;
}
.chatmessage_toast_info p {
  margin-bottom: 0;
}
.chatmessage_toast .Toastify__toast-body {
  padding: 0;
}
.chatmessage_toast .Toastify__toast-icon {
  display: none;
}

.chat_breadcrumb_visible {
  display: none;
}
.chat_icon_color path {
  stroke: #919294;
}
@media only screen and (max-width: 1000px) {
  .chat_right {
    width: calc(100% - 320px);
  }
}
@media only screen and (max-width: 850px) {
  .chat_visible {
    display: none;
  }
  .chat_breadcrumb_visible {
    display: initial;
  }
  .chat_left {
    width: 500px;
    border-radius: 30px;
    min-width: 320px;
  }
  .chat_right {
    width: 100%;
    border-radius: 30px;
  }
  .chatMessage {
    border-radius: 30px;
  }
  .chatmessage_header {
    border-top-left-radius: 30px;
  }
  .chatmessage_footer {
    border-bottom-left-radius: 30px;
  }
  .chatlist_header {
    border-top-right-radius: 30px;
  }
}
