.editProfile_img {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px auto 24px;
}
.editProfile_img .ant-image {
  width: 121px !important;
  height: 121px !important;
  border-radius: 50%;
}
.editProfile_img .ant-image img {
  border: 7px solid #F7F8FA;
  width: 121px !important;
  height: 121px !important;
  border-radius: 50%;
  object-fit: cover;
}
.editProfile_img .ant-image-mask {
  border-radius: 50%;
}
.editProfile_editSvg {  
  margin-top: auto;
  background-color: #F7F8FA;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  z-index: 200;
  margin-left: -45px;
}
.editProfile_img .ant-upload-list-picture-card-container {
  display: none;
}
.editProfile_img .ant-upload.ant-upload-select-picture-card {
  width: 25px !important;
  height: 121px !important;
  margin-left: 0px;
  margin-bottom: 8px;
  margin-right: 0 !important;
  text-align: center;
  vertical-align: top;
  background-color: #fff !important;
  border: none !important;
  cursor: pointer;
}
.editProfile_img .ant-upload-list {
  width: 25px;
  display: flex;
  justify-content: center;
}
.editProfile_img .ant-tooltip-placement-top {
  padding-bottom: 8px;
  display: none;
}
.editProfile_img .ant-upload-picture-card-wrapper {
  width: 25px !important;
}
.editprofile_main_button{
  width: 100%;
}
.editprofile_main_button button{
  width: 100% !important;
}
.editProfile_change_password{
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px; 
  text-decoration-line: underline; 
  color: #007CFF;
  cursor: pointer;
}
