.advertise_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  padding-top: 60px;
}
.advertise_section p {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: -5px;
  margin-top: 10px;
}
/* .advertise_section .input-range__slider:first-child {
  display: none !important;
} */
.advertise_section .input-range__slider {
  background: none;
  border: none;
  background-image: url("../Images/filterOrder.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 28px;
  height: 28px;
  margin-bottom: 20px;
  position: absolute;
  top: -16px;
  left: -6px;
}
.advertise_section .input-range__label--value {
  position: absolute;
  top: -2.8rem;
}
.advertise_section .input-range__track--active {
  background: #d7435c !important;
}
.advertise_section .input-range__track {
  background: var(--main);
}
.advertise_section .input-range__label-container {
  font-size: 18px;
  font-weight: 400;
}
.advertise_section .input-range__label--min .input-range__label-container {
  display: none;
}
.advertise_section .input-range__label--max .input-range__label-container {
  display: none;
}
.advertize_ranges {
  width: 350px;
}
.advertize_ranges p {
  margin-bottom: 30px;
}

.advert_share {
  width: 100%;
  height: calc(68vh - 167px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.advert_share__box {
  width: 350px;
}
