.profile_drower_header {
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
  text-align: center;
  color: #000000;
}
.profile_drower_header img {
  float: left;
}
.profile_infroms {
  width: 100%;
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 20px;
  padding: 8px 16px;
  margin: 32px 0 24px;

  font-size: 13px;
  line-height: 16px;
  color: #000000;
}
.profile_informs__img {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin-right: 16px;
}
.profile_informs_right p {
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 4px;
}
.profile_informs_svg {
  width: 72px;
  height: 72px;
  border: 1px solid #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 16px;
}
.profile_informs_right_svg {
  margin-left: auto;
  cursor: pointer;
}
.profile_content {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 22px 21px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #f3f2f8;
}
.profile_content svg {
  min-width: 24px;
}
.profile_content:nth-child(2) {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.profile_content:last-child {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.profile_content__box {
  width: 100%;
  color: black;
  display: flex;
  align-items: flex-end;
  transition: 0.3s;
}
.profile_content__box:hover {
  color: var(--main);
}
.profile_content__box svg path {
  transition: 0.3s;
}
.profile_content__box:hover .profile_contetn__right svg path {
  fill: var(--main) !important;
}
.profile_content__menu {
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  /* color: #000000; */
  cursor: pointer;
}
.ant-modal-confirm-body .anticon-check-circle {
  display: none;
}
.profile_delete__content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: #000000;
}
.profile_delete__content img {
  width: 120px;
  object-fit: cover;
  margin-bottom: 20px;
}
.profile_delete__content p {
  font-size: 20px;
}
.profile_delete__buttons {
  display: flex;
  gap: 20px;
}
.profile_delete__buttons button {
  border: 2px solid var(--main);
  width: 160px;
  border-radius: 53px;
  background-color: white;
  padding: 8px 15px;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.38px;
}
.profile_section .profile_delete__buttons button:first-child {
  background-color: var(--main) !important;
  color: white;
}
.profile_delete_button1 {
  background-color: var(--main) !important;
  color: #ffffff;
}
.profile_delete_button2 {
  background-color: white !important;
  color: var(--main) !important;
}
.profile_delete__buttons button:nth-child(2) {
  background-color: var(--main);
  color: white;
}
.profile_delete__buttons button:hover {
  transform: scale(1.02);
}
.ant-modal-content {
  border-radius: 20px !important;
}
.ant-modal-confirm-btns {
  display: none;
}
@media screen and (max-width: 500px) {
  .profile_delete__buttons {
    flex-direction: column;
  }
  .profile_delete__buttons button {
    width: 220px;
  }
}
