.notice_section {
  margin-top: 20px;
}

/* Notice modal */
.notice_modal_svg {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}
.notice_modal_svg2 {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.notice_modal_content h2 {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #000000;
  text-align: center;
}
.notice_modal_content p {
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #000000;
}
.notice_modal_content h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.38px;
  color: #343a40;
  text-align: center;
  margin: 20px;
}
.notice_modal_content h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.38px;
  color: #343a40;
  text-align: center;
  margin: 20px;
}

/* Notice setting */
.notice_header {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.notice_header h1 {
  margin-bottom: 0px;
}
.notice_setting_svg {
  font-size: 24px;
  cursor: pointer;
}
.notice_setting_svg svg {
  fill: #000000;
}
.notice_setting {
  border-radius: 20px;
}
.notice_setting .writeBulletin_workDay .ant-form-item-label label {
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 20px !important;
  letter-spacing: -0.24px !important;
  color: #6d7885 !important;
}
.notice_setting .writeBulletin_workDay .ant-checkbox-group label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 12px 20px !important;
}
.notice_setting
  .writeBulletin_workDay
  .ant-checkbox-group
  label
  span:last-child {
  font-size: 17px !important;
  line-height: 20px;
  padding-top: 7px;
}

.notice_setting
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.notice_setting_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
}
.notice_setting_header .ant-form-item {
  flex-grow: 1;
}

.notice_setting_header_button {
  width: 48px;
  height: 48px;
  border: 1px solid #e7e9e8;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
}

@media screen and (max-width: 560px) {
  .notice_section {
    width: 100%;
    margin: 80px auto 30px;
  }
  .notice_box {
    width: 100%;
    margin-bottom: 20px;
  }
}

.form {
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.full {
  width: 100%;
}

.half {
  width: calc(50% - 20px);
}
