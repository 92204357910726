.login_section {
  width: 120%;
  min-height: 90vh;
  margin-left: -10%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -50px;
}

.login_container {
  width: 456px;
  /* min-height: 480px; */
  background-color: white;
  border-radius: 20px;
  position: relative;
}

.login_section .ant-tabs-tab {
  width: 200px;
  display: flex;
  justify-content: center;
  transition: 0 !important;
}

.login_section .ant-tabs-tab:hover {
  color: var(--main);
}

.login_section .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--main);
}

.login_section .ant-tabs-tab + .ant-tabs-tab {
  margin: 0px;
}

.login_section .ant-tabs-tab-active .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 1px solid var(--main) !important;
}

.ant-tabs-content {
  padding: 20px 40px;
}

.login_section label {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: rgb(129, 126, 126) !important;
}

.login_section .ant-form-item-label {
  padding: 0 !important;
}
.login_section .ant-tabs-tab-btn {
  font-size: 18px !important;
}

.login_section .btn-moving-gradient {
  width: 100%;
}
.login_section .btn-moving-gradient--green {
  box-shadow: none;
}
.login_section .buttons-section {
  width: 100%;
}

.editLogin_content {
  padding: 30px;
  text-align: center;
}

.editLogin_content h2 {
  font-size: 40px;
  font-weight: 500;
  line-height: 40px;
}

.editLogin_content p {
  line-height: 17px;
}

/* .login_section input {
  border-bottom: 1px solid var(--main) !important;
  border-radius: 0 !important;
} */

.aferta_text {
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.07px;
  color: #000000;
  padding-left: 5px;
  padding-top: 6px;
  /* margin-top: -10px; */
}

.login_section .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
}

.login-main h2 {
  width: 80%;
  font-size: 30px;
  line-height: 40px;
  color: var(--text-1);
  font-weight: 600;
  margin-bottom: 16px;
  opacity: 0.8;
}
.login-main-button-reset {
  margin-top: 16px;
  cursor: pointer;
  transition: 0.3s;
}
.login-main-button-reset:hover {
  color: var(--main);
}
.login-main-button-back {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1001;

  background: #ffffff;
  border: 1px solid #edeef0;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
  transform: matrix(-1, 0, 0, 1, 0, 0);

  position: absolute;
  top: 24px;
  left: -24px;
}

.login-sms-resend {
  display: flex;
  align-items: center;
}
.login-sms-resend button {
  padding-left: 0;
  border: none;
  padding-top: 8px;
}
.login-sms-resend button:hover {
  border: none;
}
.login-form-v {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
