.filter_section {
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
}
.filter_search__content {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 10px;
  padding: 0 20px 0 10px;
}
.filter_search__icon {
  object-fit: cover;
  height: 25px;
  cursor: pointer;
  transition: 0.2s;
  margin-right: 20px;
}
.filter_location__icon {
  cursor: pointer;
}
.filter_search__icon:hover {
  transform: scale(1.05);
}
.filter_search__input {
  width: 90%;
  border: none;
  outline: none;
  color: var(--main);
  font-weight: 400;
  font-size: 17px !important;
  line-height: 20px;
  color: #000000;
  border: none !important;
}
.filter_location__button {
  height: 60px;
  font-size: 22px; 
  font-weight: 400;
  color: #757575;
  background-color: white;
  border-radius: 15px;
  margin-left: 30px;
  border: none;
  padding: 1px 20px;
  display: flex;
  align-items: center;
}
.filter_location__button:first-child {
  border-radius: 14px;
  padding: 1px 15px;
}
.filter_location__button
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 45px !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #07e0e0 !important;
}
.ant-select-selector:hover {
  border: 1px solid #d9d9d9 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
  box-shadow: none !important;
}
.filterContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 !important;
}
.filterContent .btn-moving-gradient {
  padding: 5px 40px !important;
  float: right;
}
.filterContent .buttons-section {
  display: block;
  margin-left: auto;
}
.filter_select {
  width: 200px;
}
.filterContent_main {
  width: 100%;
}
.filterContent_form {
  width: 100%;
  margin: 0 auto !important;
}
.filtercontent_main h1 {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.38px;
  color: #000000;
}
.filter_confirm_button {
  width: 100%;
  margin-top: 24px;
}
.filter_confirm_button button {
  width: 100%;
  height: 57px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}
@media only screen and (max-width: 1350px) {
  .filter_search__content {
    width: 40%;
  }
}
@media only screen and (max-width: 1100px) {
  .filter_section {
    width: 100%;
  }
}
@media only screen and (max-width: 1000px) {
  .filter_section {
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
  }
  .filter_search__content {
    width: 100%;
  }
  .filter_location__button {
    margin-left: 0;
  }
}
@media only screen and (max-width: 650px) {
  .ant-modal {
    width: 90% !important;
  }
  .filterContent_form {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .filter_search__content {
    width: calc(100% - 94px);
    order: 1;
  }
  .filter_location__content {
    width: 100%;
    order: 3;
  }
  .filter_modal_div {
    order: 2;
  }
  .filter_location__button:first-child {
    width: 100%;
    border-radius: 14px;
    padding: 1px 10px;
  }
  .filter_section .ant-select {
    width: calc(50% - 5px);
  }
}
@media only screen and (max-width: 500px) {
  .ant-modal {
    width: 100% !important;
  }
}

@media only screen and (max-width: 450px) {
  .filter_search__content {
    width: calc(100% - 75px);
    height: 48px;
    padding: 0px 0px 0 5px;
  }
  .filter_search__icon {
    height: 25px;
    margin-right: 10px;
  }
  .filter_location__button {
    height: 48px !important;
    padding: 1px 8px !important;
  }
  .filter_location__button
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 37px !important;
  }
  .filter_search__content {
    order: 1;
  }
  .filter_location__content {
    width: 100%;
    order: 3;
  }
  .filter_modal_div {
    order: 2;
  }
  .filter_section .ant-select {
    width: calc(50% - 5px);
  }
}
