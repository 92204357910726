.navbar_section {
  height: 85px;
  width: 100%;
  background-color: var(--main);
}

.navbar_content {
  max-width: 1400px;
  width: 90%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar_logo {
  width: 150px;
  flex-shrink: 0;
}

.navbar_logo__img {
  width: 100%;
  object-fit: cover;
}

.navbar_section .ant-layout-header {
  width: 740px;
  height: 64px;
  padding: 0 !important;
  color: white;
  line-height: 64px;
  background: var(--main) !important;
}

.navbar_section .ant-menu {
  background: var(--main) !important;
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.navbar_section .ant-menu-horizontal {
  line-height: 0px;
  border: 0;
  border-bottom: 0px solid var(--main);
  box-shadow: none;
}

.navbar_adver__icon {
  height: 18px;
  margin-right: 8px;
  object-fit: cover;
  margin-bottom: 4px;
}

.navbar_adver__icon2 {
  height: 30px;
  margin-right: 12px;
  object-fit: cover;
}

.navbar_toltip__icon {
  margin-left: 7px;
  font-size: 16px !important;
  transition: 0.3s !important;
}
.navbar_toltip__icon svg {
  transition: 0.3s !important;
}
.navbar_toltip__icon svg path {
  transition: 0.3s !important;
}

.navbar_section .ant-menu-title-content {
  font-size: 18px;
}

.navbar_section .ant-menu-title-content span {
  display: flex;
  align-items: center;
}

.navbar_section .ant-menu-title-content span svg {
  margin-right: 5px;
}

.navbar_section .ant-menu-title-content span svg:first-child path {
  stroke: white;
  transition: 0.3s !important;
}

.ant-menu-item:hover .ant-menu-title-content span svg path {
  stroke: var(--hover);
}

.navbar_section .ant-menu-item-active,
.ant-menu-item-selected {
  color: var(--hover) !important;
}

.navbar_section .ant-menu-item-selected svg path {
  stroke: var(--hover) !important;
}

.navbar_section .ant-menu-item-selected a {
  color: var(--hover) !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  padding: 0px 10px !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 0px solid transparent !important;
}

.ant-menu {
  justify-content: flex-end;
}

.ant-tooltip .ant-menu {
  color: var(--main) !important;
  font-size: 16px;
  font-weight: 500;
  justify-content: flex-start !important;
}

.ant-tooltip .ant-menu-item-active,
.ant-menu-item-selected {
  color: var(--hover) !important;
}

.ant-tooltip .ant-menu:not(.ant-meu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-tooltip .ant-menu-item:hover {
  background-color: transparent !important;
}

.ant-tooltip .ant-menu-item:hover .navbar_sublinks {
  color: var(--hover);
}

.ant-tooltip .ant-menu-item:hover .navbar_sublinks svg path {
  fill: var(--hover) !important;
}

.navbar_links {
  color: white !important;
  display: flex;
  align-items: center;
  gap: 8px;
}

.navbar_links svg path {
  transition: 0.3s;
  stroke: white;
}

.navbar_links:hover {
  color: var(--hover) !important;
}

.ant-menu-item:hover .navbar_links svg path {
  stroke: var(--hover);
}

.navbar_sublinks {
  min-width: 250px;
  color: black !important;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: 0.3s;
}

.navbar_sublinks svg path {
  fill: black !important;
  opacity: 0.8;
  transition: 0.3s;
}

.navbar_sublinks:hover {
  color: var(--hover) !important;
}

.ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
  border-right-color: transparent !important;
}

.ant-tooltip-inner {
  border-radius: 8px !important;
}

.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px !important;
}

.navbar_right__content {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 9px;
  justify-content: end;
}

.navbar_favourite__icon {
  font-size: 20px;
  color: red !important;
  fill: red !important;
}

.navbar_profile__icon {
  width: 35px;
  height: 35px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-style: 20px;
  color: rgb(233, 233, 233) !important;
  transition: 0.3s;
  border: 1.5px solid white;
  border-radius: 50%;
}
.navbar_profile__icon svg {
  font-size: 18px;
}

.navbar_notice__content {
  width: 35px;
  height: 35px;
  border: 1.5px solid white;
  padding: 6px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar_notice__content img {
  width: 20px;
  object-fit: cover;
}

.navbar_language {
  margin-left: 30px;
}

.navbar_language__content {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  gap: 5px;
}

.navbar_language__span {
  color: white;
}

.navbar_language__box {
  color: rgba(221, 220, 220, 0.763);
  cursor: pointer;
}

.navbar_langiage__aktive {
  color: white;
  font-size: 16px;
  cursor: default;
}

.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 5px 6px !important;
  background-color: white !important;
  color: black !important;
}

.ant-tooltip-arrow-content {
  background-color: white !important;
}

.navbar_main__header {
  z-index: 1;
  width: 70%;
}

.navbar_burger__icon {
  font-size: 27px;
  color: rgb(233, 233, 233) !important;
  transition: 0.3s;
  display: none;
  margin-left: 20px;
}

.navbar_burger {
  display: none;
}

.burger_section {
  margin-top: 20px;
}

.burger_svg path {
  stroke: var(--main);
}

.burger_section .profile_content:first-child {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.burger_section .profile_content:nth-child(2) {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.navbar_main__header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.navbar_main__header ul {
  width: 100%;
}

@media only screen and (max-width: 1450px) {
  .navbar_main__header {
    z-index: 1;
    width: 75%;
  }
}

@media only screen and (max-width: 1350px) {
  .navbar_main__header {
    display: none;
  }

  .navbar_burger__icon {
    display: initial;
  }

  .navbar_burger {
    display: inline;
  }

  .burger_section .ant-menu-title-content {
    color: var(--main) !important;
    font-weight: 500;
    font-size: 20px;
  }

  .burger_section .ant-menu-sub .ant-menu-title-content {
    font-weight: 400;
  }

  .burger_section .ant-menu-item-selected .navbar_links {
    color: #ff4d4f !important;
  }

  .burger_section .ant-menu-item-selected .navbar_sublinks {
    color: #ff4d4f !important;
  }

  .burger_section .ant-menu-submenu-arrow {
    color: var(--main) !important;
  }

  .burger_section .ant-menu-item-selected .anticon {
    color: #ff4d4f !important;
  }

  .navbar_links {
    color: var(--main) !important;
    font-size: 20px;
  }

  .navbar_sublinks {
    color: var(--main) !important;
  }

  .burger_section .anticon {
    color: var(--main) !important;
    font-weight: 500;
    font-size: 20px !important;
  }

  .burger_section .ant-menu-item:hover .anticon {
    color: #ff4d4f !important;
  }

  .navbar_links:hover {
    color: #ff4d4f !important;
  }

  .navbar_sublinks:hover {
    color: #ff4d4f !important;
  }
}

@media only screen and (max-width: 500px) {
  .navbar_section {
    height: 85px;
  }

  .navbar_logo {
    width: 125px;
  }

  .navbar_burger__icon {
    margin-left: 5px;
  }

  .navbar_language {
    margin-left: 5px;
  }
  .navbar_language__content {
    font-size: 12px;
  }

  .navbar_langiage__aktive {
    font-size: 13px;
  }

  .navbar_notice__content img {
    width: 15px;
  }

  .navbar_profile__icon {
    font-weight: bold;
    font-style: 14px;
    padding: 6px;
  }
  .navbar_profile__icon svg {
    font-size: 16px;
  }
}
