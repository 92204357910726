.form_container {
  width: 856px;
  margin: 0px auto;
}
.container_title {
  margin-top: 32px;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  color: #212121;
}
.container_title2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #212121;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 2px solid #f0f0f0;
}
.half_width {
  width: calc(50% - 24px);
}
.full_width {
  width: 100%;
}
.keyword_width {
  width: calc(100% - 40px) !important;
}
.writeBulletin_section {
  width: 100%;
  margin: 24px auto;
  padding: 24px;

  background: #ffffff;
  border: 1px solid #eeeff1;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
.form_display {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.writeBulletin_workType label {
  font-size: 18px;
  font-weight: 500;
  display: block;
  margin-left: 30px;
}
.writeBulletin_workType .ant-radio-inner {
  border: 2px solid #e7e9e8 !important;
  width: 20px;
  height: 20px;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #008c8c !important;
}
.writeBulletin_workType .ant-radio-inner::after {
  background-color: var(--main);
  transform: scale(2);
  width: 12px !important;
  height: 12px !important;
  margin: 0;
  transform: scale(1) translate(-50%, -50%) !important;
}
.writeBulletin_workDay {
  margin-bottom: 10px;
}
.writeBulletin_workDay label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-right: 15px;
}
.writeBulletin_workDay .ant-row {
  height: 50px;
}
.writeBulletin_workDay .ant-checkbox-inner {
  width: 30px !important;
  height: 30px !important;
  border: 2px solid #e7e9e8;
  border-radius: 9px;
}
.writeBulletin_workDay .ant-checkbox-checked::after {
  border: none !important;
}
.writeBulletin_workDay .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--main);
  border-color: var(--main);
}
.writeBulletin_workDay .ant-checkbox-checked .ant-checkbox-inner::after {
  transform: rotate(45deg) scale(1.5) translate(-20%, -60%);
  margin-left: -1px !important;
}
.writeBulletin_location {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.writeBulletin_section .filter_select {
  width: 380px !important;
  /* border: 1px solid #e7e9e8 !important; */
}
.ant-select-selection-item {
  font-weight: 400 !important;
  font-size: 15px !important;
  letter-spacing: -0.24px !important;
  color: #000000 !important;
}
.writeBulletin_section .filter_option {
  font-size: 20px !important;
  font-weight: 500 !important;
}
.writeBulletin_timePicker {
  width: 180px !important;
  border: 1px solid #e7e9e8 !important;
  border-radius: 14px !important;
  height: 48px;
}
.writeBulletin_jins {
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #6d7885;
  display: block;
}
.writeBulletin_description {
  border: 1px solid #e7e9e8 !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  border-radius: 17px !important;
  padding: 10px;
  height: auto !important;
}
.writeResume_universitet__margin {
  margin-bottom: 0 !important;
}
.writeResume_universitet__checked .ant-checkbox {
  top: 0.4em !important;
}
.writeResume_universitet__checked .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #e7e9e8;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #e7e9e8 !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: var(--main) !important;
  border: 2px solid var(--main);
}
.writeResume_universitet__checked .ant-checkbox-checked::after {
  border: none !important;
}
.writeResume_universitet__checked .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--main);
  border-color: var(--main);
}
.writeResume_universitet__checked .ant-checkbox-inner::after {
  margin-left: 0.2px !important;
  margin-top: -0.4px;
}
.writeResume_universitet input {
  border: none !important;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
}
.writeResume_universitet hr {
  border-top: 1px solid #e7e9e8;
  margin: 0;
  padding: 0;
}
.collapse_hr {
  border-top: 1px solid #e7e9e8;
  margin: 0;
  padding: 0;
  margin: -24px 0 20px;
}
.writeResume_footer {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
.writeBulletin_buttons {
  width: 100%;
  display: flex;
  justify-content: end;
}
.writeBulletin_buttons button {
  width: 200px !important;
  height: 50px;
  border-radius: 17px;
}
.label_p {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #6d7885;
}
.writeBulletin_wotkPrice__inputs {
  width: 180px;
  margin-bottom: 10px !important;
}
.breadcrumb_style {
  display: inline-block;
  margin-top: 32px !important;
}
.breadcrumb_style span {
  font-weight: 500;
  font-size: 16px;
}
.breadcrumb_style a {
  font-weight: 500;
  font-size: 16px;
}
.breadcrumb_style a:hover {
  color: var(--main) !important;
}
.ant-collapse-header {
  padding-left: 0 !important;
}
.collapse_header {
  width: 100%;
  display: flex;
  gap: 8px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #6d7885;
  position: relative;
}
.unchecked_span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #e7e9e8;
}
.checked_span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--main);
  border: 2px solid var(--main);
  display: flex;
  justify-content: center;
  align-items: center;
}
.checked_span svg {
  color: white;
  font-size: 12px;
  font-weight: bold;
}
.ant-checkbox-wrapper span:nth-child(2) {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #6d7885;
}
.required_label {
  position: relative;
}
.required_label::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1;
  content: "*";
}
/* Subtle moving gradient with shadow */

.btn-moving-gradient {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;

  padding: 13px 30px;
  color: #fff;
  cursor: pointer;
  border: none;
  background-size: 300% 100%;
  border-radius: 17px;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.btn-moving-gradient:hover {
  background-position: 100% 0;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.btn-moving-gradient--red {
  background-image: linear-gradient(to right, hsl(353, 86%, 54%), hsl(393, 86%, 54%), hsl(380, 86%, 54%), #ef233c);
  box-shadow: 0 4px 15px 0 hsl(393, 86%, 54%);
}
.btn-moving-gradient--green {
  background-image: linear-gradient(to right, var(--main), #06b6b6, #05acac, #008c8c);
  box-shadow: 0 4px 10px 0 var(--main);
}
.btn-loading {
  display: flex;
  justify-content: center;
  gap: 20px;
  cursor: initial;
  pointer-events: none;
  opacity: 0.5;
}

.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

/* Responsive section */
@media only screen and (max-width: 1100px) {
  .writeBulletin_section {
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 1100px) {
  .writeBulletin_section {
    margin: 40px auto;
  }
  .writeBulletin_location {
    justify-content: flex-start;
    gap: 40px;
  }
}
@media only screen and (max-width: 960px) {
  .form_container {
    width: 100%;
    margin: 20px auto;
  }
  .form_display {
    flex-direction: column;
  }
  .writeBulletin_location {
    flex-direction: column;
    gap: 0px;
  }
  .half_width {
    width: 100%;
  }
  .writeBulletin_section .filter_select {
    width: 100% !important;
  }
  .writeBulletin_content,
  .writeBulletin_content__right {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .writeBulletin_section {
    margin: 10px auto;
  }
  .container_title {
    margin-top: 20px;
    text-align: center;
  }
}
@media only screen and (max-width: 530px) {
  .writeBulletin_location {
    flex-direction: column;
    gap: 0px;
  }
}
@media only screen and (max-width: 500px) {
  .writeBulletin_section .ant-picker {
    width: 130px !important;
  }
  .writeBulletin_wotkPrice__inputs {
    width: 130px;
  }
  .writeBulletin_workDay label {
    margin-right: 4px;
  }
}
