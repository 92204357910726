.footer_section {
  background-color: var(--main);
  width: 100%;
  min-height: 32vh;
}

.footer_box {
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
  padding-top: 60px;
}

.footer_content {
  width: 100%;
  display: flex;
  gap: 5%;
  color: #eff8f8;
}

.footer_content h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 50px;
  color: #eff8f8;
  margin-bottom: 30px;
}

.footer_informs {
  width: 20%;

  font-weight: 500;
  line-height: 20px;
}

.footer_informs p {
  margin-top: 40px;
}

.footer_connection {
  width: 20%;
}

.footer_connection ul {
  list-style: none;
  padding-left: 0;
}

.footer_connection ul li {
  line-height: 15px;
  display: flex;
  transition: 0.5s;
  cursor: copy;
}

.footer_connection ul li:hover {
  color: var(--hover);
}

.footer_connection svg {
  width: 20px;
}

.footer_app__icons {
  margin-top: -55px;
  transition: 1s;
  transform: scale(0.9);
}

.footer_app__icons_img {
  width: 270px;
  object-fit: cover;
  cursor: pointer;
}

.footer_app__icons img:hover {
  transform: scale(1.03);
}

.footer_bottom {
  border-top: 0.5px solid #c1c1c16e;
  margin-top: 20px;
  padding-top: 20px;
  color: white;
  font-size: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.footer_bottom span {
  width: 22px;
  height: 22px;
  padding: 2px;
  border: 0.5px solid white;
  border-radius: 50%;
  font-size: 10px;
}

.footer_bottom a {
  color: rgb(0, 183, 255);
  color: white;
  font-size: 17px;
  text-decoration: underline;
  line-height: 17px;
  margin-left: 10px;
}

.footer_bottom a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1100px) {
  .footer_content {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer_informs,
  .footer_connection {
    width: 40%;
  }

  .footer_app__content {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
}

@media screen and (max-width: 600px) {
  .footer_content {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer_box {
    padding-bottom: 100px;
  }

  .footer_informs,
  .footer_connection {
    width: 100%;
  }

  .footer_app__content {
    text-align: start;
    margin-top: 10px;
  }

  .footer_content h3 {
    font-size: 20px;
    line-height: 50px;
    margin-bottom: 10px;
  }

  .footer_app__content h3 {
    line-height: 30px;
  }

  .footer_app__icons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer_app__icons img {
    margin-bottom: -30px;
  }

  .footer_bottom {
    margin-top: 40px;
  }
}