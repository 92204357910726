.paying_section {
  padding: 10px;
}
.paying_section p {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: #000000;
  margin-bottom: 23px;
}
.paying_section h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
}
.paying_section .ant-form-vertical .ant-form-item {
  flex-direction: row !important;
}
.paying_types_wrap {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
}
.paying_type {
  width: 132px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #333333;
  border-radius: 10px;
  cursor: pointer;
}
.paying_type_active {
  border: 1px solid #e96a00;
}
.paying_form {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.paying_form2 {
  width: 130px;
  display: inline-block;
}
.paying_form3 {
  width: 90px;
  display: inline-block;
}
.paying_button {
  width: calc(100% - 10px) !important;
  margin: 20px 5px 15px !important;
}
.paying_back_button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1001;

  background: #ffffff;
  border: 1px solid #edeef0;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
  transform: matrix(-1, 0, 0, 1, 0, 0);

  position: absolute;
  top: 16px;
  left: -20px;
}
/* Tab classes */
.paying_tab .ant-tabs-content {
  padding: 0px;
}
.paying_tab .ant-tabs-nav {
  display: none !important;
  margin: 0;
}
@media only screen and (max-width: 500px) {
  .payment_modal .ant-modal-body {
    padding: 32px 6px 16px !important;
  }
}
