.App {
  background-color: #efefef;
  min-height: 100vh;
  width: 100%;
  display: inline-block;
}

.app_content {
  max-width: 1400px;
  min-height: calc(68vh - 117px);
  width: 90%;
  margin: 0 auto;
  padding-bottom: 50px;
}

.arrow_up__content {
  width: 40px;
  height: 40px;
  background-color: var(--main);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.arrow_up__content svg {
  color: white;
  font-size: 20px;
}

.ant-back-top {
  right: 30px !important;
}

label {
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 20px !important;
  letter-spacing: -0.24px !important;
  color: #6d7885 !important;
  height: auto !important;
}

input,
.ant-input,
.ant-input-number,
.ant-input-affix-wrapper,
.ant-picker {
  border: 1px solid #e7e9e8 !important;
  border-radius: 14px !important;

  font-weight: 400 !important;
  font-size: 17px !important;
  line-height: 20px !important;
  color: #000000 !important;
  height: 48px !important;
}
.ant-input-affix-wrapper {
  height: 50px !important;
}
.ant-input-number {
  width: 100% !important;
  border: none !important;
}
textarea.ant-input {
  font-weight: 400;
  font-size: 15px !important;
  line-height: 20px !important;
  letter-spacing: -0.24px;
  color: #000000 !important;
}

.ant-picker input {
  border: none !important;
  height: auto !important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 14px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #e7e9e8 !important;
  border-radius: 14px;
  height: 50px !important;
  display: flex;
  align-items: center;
}

.ant-form-item,
.ant-form-item-has-error,
.ant-form-item-has-success,
.ant-form-item-with-help,
input,
.ant-input,
.ant-input-number,
.ant-input-affix-wrapper,
.ant-picker {
  transition: 0 !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  margin-left: 1.5px !important;
}

.ant-checkbox-wrapper {
  font-size: 17px !important;
  line-height: 20px !important;
  color: #000000 !important;
}

.ant-radio-wrapper {
  font-weight: 400 !important;
  font-size: 17px !important;
  line-height: 20px !important;
  color: #000000 !important;
  display: flex !important;
  align-items: center !important;
  margin-bottom: 8px !important;
}

.ant-radio-wrapper:last-child {
  margin-bottom: 0px !important;
}

.ant-radio-checked .ant-radio-inner {
  border: 10px solid #008c8c !important;
}
.ant-radio {
  top: 0 !important;
}

.anticon-exclamation-circle {
  display: none !important;
}

.ant-drawer-wrapper-body {
  background: #f7f8fa !important;
}
.ant-collapse-arrow {
  display: none !important;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0px !important;
  padding-bottom: 0px;
  padding: 0px !important;
}

.masked_input_wrap {
  outline: none;
  border: none;
  border-bottom: 1px solid var(--main);
  padding: 6.5px 11px;
  font-size: 16px;
  margin: 10px 0 24px;
  background-color: #e8f0fe;
}

.login_section .masked_input {
  border: none !important;
  outline: none;
  background-color: #e8f0fe !important;
}

.nodata_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.nodata_title {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  margin-bottom: -30px;
  font-weight: 600;
  font-size: 32;
  z-index: 10 !important;
  font-style: italic;
}

.share_section {
  display: flex;
  align-items: center;
}

.share_icons {
  width: 120%;
  display: flex;
  gap: 7px;
  padding: 5px 0px 2px;
}

.share_icons img {
  height: 40px;
  cursor: pointer;
}
.link {
  cursor: pointer;
  transition: 0.3s;
}
.link:hover {
  color: var(--main);
}

/* Tab classes */
.ant-tabs-nav {
  margin-bottom: 25px;
}
.ant-tabs-nav-list {
  width: 100%;
}
.ant-tabs-nav-list .ant-tabs-tab {
  width: 50%;
  text-align: center;
}
.ant-tabs-nav-list .ant-tabs-tab-btn {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #606266;
}
.ant-tabs-nav-list:hover .ant-tabs-tab-btn {
  color: var(--main);
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--main);
}
.ant-tabs-ink-bar {
  background: var(--main);
}
.singup-tab > .ant-tabs-nav {
  display: none !important;
}
.login-main .ant-tabs-content {
  padding: 0px 2px 5px;
}
.ant-tabs-ink-bar {
  background: var(--main) !important;
}
.ant-input-affix-wrapper input {
  border: none !important;
}
.ant-input-affix-wrapper {
  padding: 0 11px !important;
}

@media only screen and (max-width: 700px) {
  .app_content {
    width: 94%;
  }
}
